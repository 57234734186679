// @flow

import { connect } from "react-redux";
import React from "react";
import { SortableHandle } from "react-sortable-hoc";

import {
  TileWrapper,
  Tile as StyledTile,
  Action,
  UserSegment as StyledUserSegment,
  Menu
} from "./styles";
import UserSegments from "../UserSegments";
import Icons from "src/icons";
import { getTile } from "src/reducers";
import type { AppState } from "src/types";
import { useCallback } from "react";

type Props = {
  id: number,
  tile: {
    title: string,
    subTitle: string,
    settings: Object
  },
  deleteTile: Function,
  editTile: Function
};

const DragHandle = SortableHandle(() => <Icons type="dottedSort" />);

const Tile = ({ id, tile, deleteTile, editTile }: Props) => {
  const handleDelete = useCallback(() => {
    deleteTile(id);
  }, [deleteTile]);

  const handleEdit = useCallback(() => {
    editTile({ id, tile });
  }, [id, tile, editTile]);

  const tileColor = tile?.settings?.tileColor;

  return (
    <TileWrapper>
      <DragHandle />
      <StyledTile color={tileColor}>
        <div>{tile.title}</div>

        <Menu>
          <Action color={tileColor} onClick={handleDelete}>
            <Icons type="newDelete" />
          </Action>

          <Action color={tileColor} onClick={handleEdit}>
            <Icons type="editPencil" />
          </Action>
        </Menu>
      </StyledTile>
      <StyledUserSegment>
        <UserSegments tileId={id} color={tileColor} />
      </StyledUserSegment>
    </TileWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  tile: getTile(app, props.id)
});

export default connect(mapStateToProps)(Tile);
