// @flow

import React from "react";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";

import BlueOutlineButton from "src/components/Buttons/BlueOutline";

import { openCsvUploadModal } from "src/actions/workflows";
import UploadCSVModal from "./UploadCSVModal";
import Icon from "src/icons";

import type { ProcessTemplateId } from "src/types";

type Props = {
  templateId: ProcessTemplateId
};

const UploadCSV = ({ templateId }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  return (
    <>
      <BlueOutlineButton
        leftIcon={<Icon type="uploadCloud" />}
        onClick={() => {
          dispatch(openCsvUploadModal({ templateId }));
          onOpen();
        }}
      >
        Upload CSV
      </BlueOutlineButton>

      {isOpen && (
        <UploadCSVModal
          isOpen={isOpen}
          onClose={onClose}
          templateId={templateId}
        />
      )}
    </>
  );
};

export default UploadCSV;
