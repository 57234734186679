// @flow

import React, { memo, useMemo, useContext } from "react";
import { Text, Flex } from "@chakra-ui/react";

import { Value as StyledValue } from "./styles";
import Icon from "src/icons";
import type { ReactRef } from "src/types";
import appLocation from "src/constants/location";
import { ChecklistFieldContext } from "src/components/Dock/Checklist/Field";
import { getDaysFromToday, getWeekNumber } from "src/utils/checklist";

type Props = {
  value: ?string,
  active: ?boolean,
  fieldRef: ReactRef,
  location?: string,
  labelWidth?: string,
  dropdown?: boolean,
  setDropdown?: Function
};

const Value = ({
  value,
  active,
  fieldRef,
  location,
  labelWidth,
  dropdown,
  setDropdown
}: Props) => {
  const { fieldWidth } = useContext(ChecklistFieldContext);

  const timeline = useMemo(() => {
    if (active === false) {
      return "present";
    }

    if (!value) {
      return "noValue";
    }

    const dayDiff = getDaysFromToday(value);

    if (dayDiff === 0 || dayDiff === -1) {
      return "present";
    }

    if (dayDiff < 0) {
      return "future";
    }

    return "past";
  }, [active, value]);

  const newValue = useMemo(() => {
    if (!value) {
      return "Due";
    }
    const todayDate = new Date();
    const valueDate = new Date(value);
    const dayDiff = getDaysFromToday(value);
    const todayWeekNo = getWeekNumber(todayDate),
      valueWeekNo = getWeekNumber(valueDate);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit"
    }).format(valueDate);

    if (dayDiff === 0) {
      return "Today";
    } else if (dayDiff === -1) {
      return "Tomorrow";
    } else if (dayDiff === 1) {
      return "Yesterday";
    } else if (todayWeekNo === valueWeekNo && dayDiff < 0 && dayDiff > -7) {
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short"
      }).format(valueDate);
    }
    return formattedDate;
  }, [value]);

  return (
    <StyledValue
      tabIndex={0}
      timeline={timeline}
      ref={fieldRef}
      location={location}
      onKeyPress={() => setDropdown && setDropdown(!dropdown)}
      maxW={
        labelWidth ? labelWidth : fieldWidth > 0 && fieldWidth < 600 && "60px"
      }
    >
      <Flex w={4} align="center">
        {location === appLocation.chatroomHeader && dropdown ? (
          <Icon type="dateActive2" timeline={timeline} />
        ) : (
          <Icon type="dateActive" timeline={timeline} />
        )}
      </Flex>
      <Text as="span">{newValue}</Text>
    </StyledValue>
  );
};

export default memo<Props>(Value);
