// @flow

import React, { useState, useEffect, useRef } from "react";

import { useCombobox } from "downshift";

import type { UnifizeDropdownRow } from "../utils";

import {
  Dropdown as DropdownContainer,
  DropdownMenu as StyledMenu,
  SearchInput as StyledInput,
  DropdownButton as StyledButton,
  DropdownContent as StyledContent
} from "./styles";
import DropdownElement from "./DropdownElement";

type Props = {
  label: string,
  elements: Array<UnifizeDropdownRow>,
  selectedElement: ?UnifizeDropdownRow,
  placeholder?: string,
  handleSelect?: (...args: any) => void,
  handleRemove?: () => void,
  onChange?: Object => void,
  onMount?: (...any) => void | (() => void)
};

/**
 * A basic search filter for a UnifizeDropdownRow. Matches the input
 * with every cell's content.
 *
 * @param {string} input - The search string
 */
const getBasicDropdownFilter = (input: string) => {
  const searchStr = input.toLowerCase();
  return (element: UnifizeDropdownRow) => {
    for (const cell of element.cells) {
      if (cell[0] && cell[0].toLowerCase().includes(searchStr)) {
        return true;
      }
    }
    return false;
  };
};

const Dropdown = ({
  label,
  elements,
  handleSelect,
  selectedElement,
  onChange = () => {},
  onMount = () => {}
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [items, setItems] = useState(elements);
  const {
    isOpen,
    openMenu,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem
  } = useCombobox({
    inputValue,
    onInputValueChange({ inputValue }) {
      setInputValue(inputValue ?? "");
      setItems(elements.filter(getBasicDropdownFilter(inputValue)));
    },
    items,
    itemToString(item: UnifizeDropdownRow) {
      return item.cells.length ? item.cells[0][0] : "";
    },
    onStateChange: onChange
  });
  const searchRef = useRef<?HTMLInputElement>();
  useEffect(() => {
    if (isOpen && searchRef.current) {
      // Focus and clear input upon opening
      searchRef.current.focus();
      setInputValue("");
      setItems(elements);
    }
  }, [isOpen]);

  useEffect(() => {
    const cleanUp = onMount({
      isOpen,
      openMenu,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      selectedItem
    });
    if (cleanUp) {
      return cleanUp;
    }
  }, []);

  useEffect(() => {
    setItems(elements);
  }, [elements]);

  useEffect(() => {
    if (selectedItem && handleSelect) {
      handleSelect(Number(selectedItem.id));
    }
  }, [selectedItem]);

  return (
    <DropdownContainer {...getComboboxProps()}>
      <StyledButton {...getToggleButtonProps()}>
        {selectedElement?.cells ? (
          <DropdownElement cells={selectedElement?.cells || []} static />
        ) : (
          label
        )}
      </StyledButton>
      <StyledContent hidden={!isOpen}>
        <StyledInput
          placeholder="Search"
          hidden={!isOpen}
          {...getInputProps({ ref: searchRef })}
        />
        <StyledMenu hidden={!isOpen} {...getMenuProps()}>
          {isOpen && items.length
            ? items.map((item, index) => (
                <DropdownElement
                  cells={item.cells}
                  key={`element-${String(item.cells)}-${index}`}
                  highlighted={highlightedIndex === index}
                  selected={selectedItem === item}
                  {...getItemProps({ item, index })}
                />
              ))
            : null}
        </StyledMenu>
      </StyledContent>
    </DropdownContainer>
  );
};

export default Dropdown;
