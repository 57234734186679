// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useCallback, useRef, useEffect } from "react";

import ChartList from "src/components/Manage/Reports/Instances/Chart/ChartList";
import NewChart from "./New";
import { ButtonOutline, IconButton } from "./styles";
import Icon from "src/icons";
import {
  setNewChartDialogAttributes,
  getReportsChartJSON,
  getCharts
} from "src/actions/chart";
import useBoolean from "src/hooks/useBoolean";
import { getTotalChartsCount, getInstanceReportId } from "src/reducers";

import appLocation from "src/constants/location";
import type { AppState } from "src/types";

type Props = {
  chartId: ?string,
  showNewChart: boolean,
  _setNewChartDialogAttributes: Function,
  _getReportsChartJSON: Function
};

const Chart = ({
  showNewChart,
  _setNewChartDialogAttributes,
  _getReportsChartJSON
}: Props) => {
  const dispatch = useDispatch();
  const reportId = useSelector(({ app }) => getInstanceReportId(app));
  const chartsCount =
    useSelector(({ app }) => getTotalChartsCount(app, reportId)) || 0;

  const { value: showDropdown, toggleBoolean, setFalse } = useBoolean();
  const outerRef = useRef(null);

  const toggleDropdown = useCallback(() => {
    toggleBoolean();
  }, [toggleBoolean]);

  useEffect(() => {
    if (chartsCount === 0) {
      dispatch(getCharts());
    }
  }, []);

  const handleNewChart = useCallback(() => {
    if (chartsCount === 0) {
      _setNewChartDialogAttributes({
        stage: 0,
        show: true
      });
    } else {
      toggleDropdown();
    }
  }, [chartsCount, _setNewChartDialogAttributes, toggleDropdown]);

  return (
    <>
      <ButtonOutline type="button" onClick={handleNewChart} ref={outerRef}>
        <Icon type="chart" />
        <span>{chartsCount === 0 && <> Create</>} Chart</span>
        {chartsCount > 0 && (
          <IconButton type="button" create={chartsCount > 0}>
            <Icon type="triangleDown" />
          </IconButton>
        )}
      </ButtonOutline>

      {showDropdown && !showNewChart && (
        <ChartList
          location={appLocation.manageView}
          handleClick={_getReportsChartJSON}
          handleClose={setFalse}
          parentRef={outerRef}
        />
      )}
      <NewChart show={showNewChart && !showDropdown} />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  showNewChart: app.charts.newChartDialog.show
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _getReportsChartJSON: getReportsChartJSON
})(Chart);
