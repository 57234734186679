// @flow

import React, { useState, useRef } from "react";

import { useDisclosure } from "@chakra-ui/react";

import { getSignedURLForUpload, uploadFileToAWS } from "src/api/file";
import InlineIcon from "src/icons";
import UnifizeButton from "src/components/Unifize/Button";
import * as colors from "src/styles/constants/colors";

import ImportModal from "./ImportModal";

const JSONImport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<?string>(null);

  const fileUploadRef = useRef(null);

  const handleFileUpload = async event => {
    setUploading(true);
    const fileData = event?.target.files[0];
    try {
      const {
        name,
        url: signedURL,
        contentType
      } = await getSignedURLForUpload(fileData.name);

      const res = await uploadFileToAWS({
        signedURL,
        fileData,
        name,
        contentType,
        handleProgress: () => {}
      });
      if (res?.status === 200) {
        setUploadedFile(name);
        setUploading(false);
        onOpen();
      }
    } catch (e) {
      console.error("Stopping file upload. Error:", e);
      setUploading(false);
    }
  };

  return (
    <>
      <UnifizeButton
        variant="default"
        handleClick={() => {
          if (fileUploadRef.current) {
            fileUploadRef.current.click();
          }
        }}
        isLoading={uploading}
        disabled={uploading}
        color={colors.lightBlue600}
        border="solid 1px"
        bgColor={uploading ? colors.lightBlue600 : "white"}
        borderRadius="4px"
        width="auto"
      >
        <InlineIcon type="downloadBlue" />
        <input
          id="jsonFileInput"
          type="file"
          accept=".json"
          style={{ display: "none" }}
          onChange={handleFileUpload}
          ref={fileUploadRef}
        />
        Import
      </UnifizeButton>
      {isOpen && uploadedFile && (
        <ImportModal
          isOpen={isOpen}
          onClose={onClose}
          fileName={uploadedFile}
        />
      )}
    </>
  );
};

export default JSONImport;
