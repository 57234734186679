// @flow

import React from "react";

import type { UnifizeDropdownRow } from "../utils";
import Icon from "src/icons";

import { DropdownCell, DropdownCellContent } from "./styles";

type Props = {
  cell: $ElementType<$PropertyType<UnifizeDropdownRow, "cells">, 0>
};

const ElementCell = ({ cell }: Props) => {
  return (
    <DropdownCell
      title={cell[0] !== null ? (cell[0]?.length ? cell[0] : "No title") : ""}
    >
      {cell[1]?.length ? <Icon type={cell[1] ?? ""} /> : null}
      <DropdownCellContent>
        {cell[0] !== null ? cell[0]?.length ? cell[0] : <i>No title</i> : null}
      </DropdownCellContent>
    </DropdownCell>
  );
};

export default ElementCell;
