// @flow

import * as R from "ramda";
import moment from "moment";

import type { Set as ImmutableSet } from "immutable";
import { CANCELED } from "src/constants/status";
import { priorityOrder } from "src/constants/priority";

import type {
  Membership,
  UnifizeChatRoom,
  CurrentFilter,
  UID,
  UserNames,
  ReadMessageCount,
  WorkflowTitle,
  UnifizeUser,
  RoomId,
  TemplateStatus,
  FieldsById,
  WorkflowSettings
} from "src/types";

const filterCancelled = (room: UnifizeChatRoom) => {
  return room.status !== CANCELED;
};

const filterByCancelled =
  ({ cancelled }: CurrentFilter) =>
  (chatItem: UnifizeChatRoom) =>
    cancelled || filterCancelled(chatItem);

const filterByArchived =
  ({ archived }: CurrentFilter, templateStatus?: TemplateStatus) =>
  (chatItem: UnifizeChatRoom) => {
    if (archived) {
      return true;
    }
    const { templateId, status } = chatItem;

    if (R.isNil(chatItem.archived) && templateStatus) {
      if (templateId) {
        return (
          templateStatus?.[`${templateId}`]?.[`${status}`]?.archiveMode !==
          "archive"
        );
      }
      return true;
    }

    return !chatItem.archived;
  };

// If filter by mine is true (myConversations) then don't show
// cancelled conversations
const filterByMine =
  ({ mine }: CurrentFilter, membership: Membership) =>
  (chatItem: UnifizeChatRoom) =>
    !mine ? true : membership.contains(parseInt(chatItem.id, 10));

const filterByUnread =
  (
    { unread }: CurrentFilter,
    readMessageCount: ReadMessageCount,
    current?: RoomId
  ) =>
  (chatItem: UnifizeChatRoom) => {
    // Don't filter unread block if that chatblock is currently open
    if (chatItem.id !== current) {
      const unreadCount = readMessageCount.get(chatItem.id)
        ? chatItem.count - readMessageCount.get(chatItem.id)
        : chatItem.count;
      return !unread ? true : unreadCount > 0;
    } else {
      return true;
    }
  };

const filterByParent =
  (filter: CurrentFilter) => (chatItem: UnifizeChatRoom) => {
    if (!filter.parent) {
      return true;
    }
    if (chatItem.parent) {
      return `${chatItem.parent}` == filter.parent;
    }
    return false;
  };

/**
 * Compares the search term to a chatroom and returns true if it
 * matches
 */
export const matchesChatroomTitle = (
  searchTerm: string,
  processTitle: string = "",
  seqNo: number | string,
  title: string = ""
) => {
  return (
    R.includes(
      R.toLower(searchTerm),
      `${R.toLower(processTitle)} ${seqNo}:${R.toLower(title)}`
    ) ||
    R.includes(
      R.toLower(searchTerm),
      `${R.toLower(processTitle)} #${seqNo}:${R.toLower(title)}`
    ) ||
    R.includes(
      R.toLower(searchTerm),
      `${R.toLower(processTitle)}#${seqNo}:${R.toLower(title)}`
    )
  );
};

const filterByText = (
  { text }: CurrentFilter,
  workflows: WorkflowTitle,
  userNames: UserNames,
  currentUser: UID,
  workflowSettings: ?WorkflowSettings
) => {
  return (chatItem: UnifizeChatRoom) => {
    const searchTerm = R.trim(text || "");
    const hideSeqNo =
      workflowSettings && chatItem.templateId
        ? workflowSettings[`${chatItem.templateId}`]?.hideAutoNumber || null
        : null;
    const seqNo = hideSeqNo ? "" : chatItem.autoNo || chatItem.seqNo || "";
    let title = chatItem.title || "";

    if (chatItem.type === "direct") {
      const uid = R.head(R.reject(R.equals(currentUser), title.split(",")));
      const user: UnifizeUser = userNames[uid] || {};
      title = R.toLower(user.displayName || user.email || "");
    }

    const processTitle = chatItem.templateId
      ? workflows[`${chatItem.templateId}`] || ""
      : "";

    return matchesChatroomTitle(searchTerm, processTitle, seqNo, title);
  };
};

const filterByType =
  ({ type }: CurrentFilter) =>
  (chatItem: UnifizeChatRoom) =>
    R.includes(chatItem.type, type);

const filterByCompletion =
  ({ active }: CurrentFilter, customStatuses: ImmutableSet<number>) =>
  (chatItem: UnifizeChatRoom) => {
    switch (active) {
      case "pending":
        return chatItem.active !== false && !chatItem.canceled;
      case "closed":
        return chatItem.active === false || chatItem.canceled || false;
      case "custom":
        if (R.type(customStatuses) === "Array") {
          return customStatuses.includes(chatItem.status);
        }
        return customStatuses.has(chatItem.status);
      default:
        return true;
    }
  };

const filterByOverdue =
  ({ overdue }: { overdue?: boolean }) =>
  (chatItem: UnifizeChatRoom) => {
    if (overdue) {
      const { dueDate } = chatItem;
      if (dueDate && chatItem.active !== false) {
        const due = moment(dueDate).diff(moment().format("YYYY-MM-DD"), "days");
        if (due && due < 0) return true;
        return false;
      }
      return false;
    }
    return true;
  };

const filterByFavourite =
  ({ favourite }: CurrentFilter, favourites: ImmutableSet<string>) =>
  (chatItem: UnifizeChatRoom) =>
    !favourite ? true : favourites.includes(chatItem.id);

const filterByApproved =
  ({ approved }: CurrentFilter) =>
  (chatItem: UnifizeChatRoom) =>
    approved ? chatItem.outcome == true : true;

const filterByOwner =
  ({ owner }: CurrentFilter, currentUser: UID) =>
  (chatItem: UnifizeChatRoom) =>
    owner ? chatItem.owner === currentUser : true;

const filterByWorkflow =
  ({ workflow }: CurrentFilter) =>
  (chatItem: UnifizeChatRoom) =>
    workflow !== null ? workflow === chatItem.templateId : true;

const filterByNewChats =
  (
    { newChats }: CurrentFilter,
    lastRead: ReadMessageCount,
    membership: Membership
  ) =>
  (chatItem: UnifizeChatRoom) =>
    newChats
      ? !lastRead.get(`${chatItem.id}`) &&
        membership.contains(parseInt(chatItem.id, 10))
      : true;

const filterByCritical =
  ({ critical }: CurrentFilter) =>
  (chatItem: UnifizeChatRoom): boolean =>
    !critical ||
    (chatItem.priority !== null && chatItem.priority === "critical");

const getSortByIcon = (active: boolean, updatedSortBy: string) => {
  if (!active) {
    return "defaultSort";
  }
  if (active && R.endsWith("ascending", updatedSortBy)) {
    return "descend";
  }
  return "ascend";
};

const getSortByDescription = (
  updatedSortBy: string,
  active: boolean,
  filterText: string
) => {
  if (R.endsWith("ascending", updatedSortBy) && active) {
    switch (filterText) {
      case "updatedAt":
        return "Stale -> Recent";
      case "status":
        return "Complete -> Pending";
      case "priority":
        return "Low -> Critical";
      case "createdAt":
        return "Oldest -> Newest";
      case "dueDate":
        return "Due later -> Due soon";
      default:
        return "";
    }
  }
  switch (filterText) {
    case "updatedAt":
      return "Recent -> Stale";
    case "status":
      return "Pending -> Complete";
    case "priority":
      return "Critical -> Low";
    case "createdAt":
      return "Newest -> Oldest";
    case "dueDate":
      return "Due soon -> Due later";
    default:
      return "";
  }
};

const showCustomStatusSegments = (
  currentFilterSortBy: string,
  currentWorkflowId: number
) => {
  const isSortByStatus = currentFilterSortBy === "status";
  const isSortByAscending = currentFilterSortBy === "status:ascending";
  const isWorkflowId = currentWorkflowId === null;

  return {
    defaultSegment:
      (isSortByStatus && isWorkflowId) || (isSortByAscending && isWorkflowId),
    customSegment:
      (isSortByStatus && !isWorkflowId) || (isSortByAscending && !isWorkflowId),
    prioritySegment: R.includes(currentFilterSortBy, R.keys(priorityOrder))
  };
};

/**
 * Return the label for the All records filter
 * @param {string} parentFieldId - parent field ID of the field on which
 * the filter can be applied
 * @param {FieldsById} fieldsById - field data stored in redux store
 * @return {string} - returns either "form" or "embedded" depending upon
 * the type of the parent field
 */
const getAllRecordsLabel = (
  parentFieldId: string,
  fieldsById: FieldsById
): string => {
  // $FlowFixMe - Optional chaining not yet supported
  const parentFieldType = fieldsById?.toJS()?.[parentFieldId]?.type;
  return parentFieldType === "form" ? "form" : "embedded";
};

export {
  filterByMine,
  filterByCompletion,
  filterByParent,
  filterByApproved,
  filterByFavourite,
  filterByOwner,
  filterByNewChats,
  filterByText,
  filterByWorkflow,
  filterByType,
  filterByOverdue,
  filterByUnread,
  filterByCritical,
  getSortByDescription,
  getSortByIcon,
  showCustomStatusSegments,
  filterByCancelled,
  filterByArchived,
  getAllRecordsLabel
};
