// @flow

import React from "react";

import UserSelect2 from "src/components/user/SelectInvite";
import { FormControl, FormLabel } from "@chakra-ui/react";

import type { NewUser } from "src/types";

type Props = {
  owner: ?NewUser,
  handleOwner: Function
};

const Owner = ({ owner, handleOwner }: Props) => (
  <FormControl px={4}>
    <FormLabel>Owner</FormLabel>
    <UserSelect2 value={owner} handleChange={handleOwner} />
  </FormControl>
);

export default Owner;
