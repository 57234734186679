// @flow

import { connect, useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import Link from "redux-first-router-link";

import EditModal from "../EditModal";
import ParentSelector from "../ParentSelector";
import { Parent as StyledParent, EditButton, TitleWrapper } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import { Label } from "src/components/Dock/Checklist/styles";
import { openConversationModal, fetchConversation } from "src/actions/chatroom";
import * as atypes from "src/constants/actionTypes";
import { NoTitle } from "src/components/chatroom/Name/styles";
import { getChatroomAddress, getTemplateId, getFieldWidth } from "src/reducers";
import Icon from "src/icons";
import { updateChatroomFromManageView } from "src/actions/workflows";
import { size } from "src/constants/processInstanceColumns";

import type { AppState, RoomId } from "src/types";

type Props = {
  selected: boolean,
  id: number,
  title: number,
  address: string,
  _openConversationModal: Function,
  _fetchConversation: Function,
  chatroomId: RoomId,
  index: number,
  templateId: ?number,
  depth: number,
  parentId: number
};

const Parent = ({
  selected,
  id,
  title,
  address,
  _openConversationModal,
  _fetchConversation,
  chatroomId,
  index,
  templateId,
  depth,
  parentId
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, "parent"));

  const openEdit = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      setIsModalOpen(true);
    },
    []
  );

  const handleConversation = useCallback(() => {
    _fetchConversation(id);
    _openConversationModal(id);
    return false;
  }, [id, _openConversationModal, _fetchConversation]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!id) {
    return (
      <StyledParent
        selected={selected}
        width={fieldWidth}
        minWidth={size.parent}
      >
        <EditModal
          handleClose={handleClose}
          isOpen={isModalOpen}
          isParent
        >
          <Label>PARENT</Label>
          <ParentSelector
            chatRoomId={chatroomId}
            index={index}
            depth={depth}
            parentId={parentId}
            id={id}
            selected={selected}
            handleClose={handleClose}
          />
        </EditModal>

        <ValueWrapper>
          <NoTitle>No Parent</NoTitle>
          <EditButton onClick={e => openEdit(e)} selected={selected}>
            <Icon type="editPencil" />
          </EditButton>
        </ValueWrapper>
      </StyledParent>
    );
  }

  if (address === undefined) {
    return (
      <StyledParent
        selected={selected}
        width={fieldWidth}
        minWidth={size.parent}
      >
        <ValueWrapper>
          <NoTitle>Private</NoTitle>
        </ValueWrapper>
      </StyledParent>
    );
  }

  return (
    <StyledParent selected={selected} width={fieldWidth} minWidth={size.parent}>
      <EditModal
        handleClose={handleClose}
        isOpen={isModalOpen}
        isParent
      >
        <Label>PARENT</Label>
        <ParentSelector
          chatRoomId={chatroomId}
          index={index}
          handleClose={handleClose}
        />
      </EditModal>
      <ValueWrapper>
        <Link
          onClick={handleConversation}
          to={{
            type: atypes.SET_CURRENT_CHATROOM_REQUEST,
            payload: {
              id: address
            },
            meta: {
              query: {
                templateId
              }
            }
          }}
          title={title}
        >
          {title ? (
            <TitleWrapper>
              <span>{title}</span>
              <EditButton onClick={e => openEdit(e)} selected={selected}>
                <Icon type="editPencil" />
              </EditButton>
            </TitleWrapper>
          ) : (
            <NoTitle>No title</NoTitle>
          )}
        </Link>
      </ValueWrapper>
    </StyledParent>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  address: getChatroomAddress(app, props.id),
  templateId: getTemplateId(app, props.id)
});

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal,
  _fetchConversation: fetchConversation,
  _updateChatroomAttributes: updateChatroomFromManageView
})(Parent);
