// @flow

import * as R from "ramda";
import firebase from "firebase/app";
import moment from "moment";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/react";
import {
  call,
  put,
  select,
  takeEvery,
  take,
  all,
  race,
  takeLatest
} from "redux-saga/effects";
import localforage from "localforage";

import { rootEndpoint } from "src/api";
import * as api from "src/api/auth";
import * as chatroomApi from "src/api/chatroom";
import { getStorage } from "src/api/storage";
import { BETA_TESTERS } from "src/constants/users";
import * as atypes from "src/constants/actionTypes";
import { getSignInResetCode, getLoginAs } from "src/reducers";
import getAppState, {
  getUser,
  getSignUpEmail,
  getRequestedPage,
  getLocation,
  getOAuthAppName,
  getRequestedChatRoom,
  getCurrentUserId
} from "src/selectors";
import {
  authenticationFailed,
  setCurrentUser,
  setCurrentUserEmail,
  clearAllErrors,
  resetPasswordFailed,
  forgotPasswordFailed,
  resetSent,
  resetSuccess,
  setResetCode
} from "src/actions/auth";
import chatroomMetaData from "src/constants/chatroomMetaData";
import { rsf } from "src/db";
import { getDueDate } from "src/utils";

import type { Action } from "src/types";

function* handleFirebaseSignIn(email: string, password: string): any {
  try {
    yield call(api.signInUser, email, password);

    const { displayName: name, uid } = yield call(api.getCurrentUser);
    yield put(setCurrentUser({ name, email, uid }));
  } catch (error) {
    yield put(authenticationFailed(error));
  }
}

function* handleAuthRequest(action: Action): any {
  const { email, password } = action.payload;
  yield put(clearAllErrors());
  yield call(handleFirebaseSignIn, email, password);
}

function* watchAuthenticationRequest(): any {
  yield takeEvery(atypes.API_AUTH_REQUEST, handleAuthRequest);
}

function* handleForgotPassword(action: Action): any {
  yield put(clearAllErrors());
  const { email } = action.payload;
  yield put(setCurrentUserEmail(email));
  try {
    yield call(api.resetPassword, email);
    yield put(resetSent());
  } catch (error) {
    switch (error.status) {
      case 403:
      case 404:
        yield put(
          forgotPasswordFailed(
            new Error(
              "Unable to reach the server, please check your internet connection."
            )
          )
        );
        break;
      default:
        yield put(
          forgotPasswordFailed(
            new Error("Cannot reset password for the specified email")
          )
        );
    }
  }
}

function* watchForgotPasswordRequest(): any {
  yield takeEvery(atypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
}

function* handleResetPassword(action: Action): any {
  yield put(clearAllErrors());
  try {
    const { email, password } = action.payload;
    if (!email) {
      throw new Error("Email Missing");
    }
    const actionCode = getSignInResetCode(yield select(getAppState));
    yield call(api.confirmReset, password, actionCode);
    yield put(resetSuccess());
    const continueToApp = yield take(atypes.CONTINUE_TO_APP);
    if (continueToApp) {
      yield call(handleFirebaseSignIn, email, password);
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

function* verifyResetCode(action: Action): any {
  const { oobCode } = action.payload;
  try {
    const email = yield call(api.validatePasswordResetCode, oobCode);
    yield put(setCurrentUserEmail(email));
    yield put(setResetCode(oobCode));
    yield put({ type: atypes.SIGN_IN, payload: { page: "reset" } });
  } catch (e) {
    yield authenticationFailed(e);
  }
}

function* watchVerifyResetCode(): any {
  yield takeEvery(atypes.VERIFY_RESET_CODE, verifyResetCode);
}

function* watchResetPasswordRequest(): any {
  yield takeEvery(atypes.RESET_PASSWORD_REQUEST, handleResetPassword);
}

function* monitorAuthSate(): any {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { isSingleResponse: isLiteApp } = (yield select(getAppState)).srw;
    const { user } = yield take(channel);

    if (user) {
      if (isLiteApp) {
        try {
          const authToken = yield call(api.getAuthToken);
          yield call(api.authenticate, authToken);
        } catch (e) {
          console.error(e);
        }
      } else {
        // Check for a session
        // If there is none, ask for one
        try {
          if (window.ReactNativeWebView) {
            return;
          }
          yield call(rootEndpoint);
        } catch (error) {
          console.error(error);

          const authToken = yield call(api.getAuthToken);

          Sentry.addBreadcrumb({
            category: "message",
            message: "Authenticating with server",
            level: "info"
          });

          yield call(api.authenticate, authToken);

          Sentry.captureMessage("Successfully authenticated with server", {
            level: "info"
          });
        }
      }

      if (isLiteApp) {
        console.log("User signed into SRW");
        yield put({
          type: atypes.SRW_SIGNED_IN,
          payload: user
        });
      } else {
        if (window.ReactNativeWebView) {
          return;
        }
        console.log("User signed in");
        yield put({ type: atypes.SIGNED_IN, payload: user });
        window.currentUserUID = user.uid;
      }
    } else {
      console.log("User *NOT* signed in");
      const isSwitchingOrg = sessionStorage.getItem("switchingOrgs") !== "true";
      if (isSwitchingOrg) {
        // Just to prevent the flow from going inside the switching org
        // login again
        sessionStorage.setItem("switchingOrgs", "true");
      }
      Sentry.captureMessage("User *NOT* signed in", {
        level: "info"
      });
      yield put({
        type: atypes.SIGNED_OUT,
        payload: {
          isSwitchingOrg
        }
      });
    }

    yield put({ type: atypes.AUTH_CHANNEL_EVENT_COMPLETE });
  }
}

function* loadConversations(): any {
  const signUpEmail = yield select(getSignUpEmail);
  const loginAsUser = getLoginAs(yield select(getAppState));
  const { uid } = yield select(getUser);
  // Chack if user is online
  if (navigator.onLine) {
    if (!signUpEmail && !loginAsUser && uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({ type: atypes.GET_ORGS_REQUEST, payload: { uid } });
    }
  } else {
    yield put({ type: atypes.NO_NETWORK });
  }
}

function* watchLoadConversations(): any {
  yield takeEvery(atypes.LOAD_CONVERSATIONS, loadConversations);
}

function* checkIfEmailVerified({ payload }: Action) {
  const location = yield select(getLocation);

  const authProvider = yield call(api.getAuthProvider, payload.email);

  // Save the auth provider data in redux store
  yield put({
    type: atypes.SET_AUTH_PROVIDER,
    payload: {
      tenantId: authProvider.tenantId,
      provider: authProvider.provider
    }
  });

  if (location.type === atypes.ORG_CLONE_PAGE) {
    return;
  }

  try {
    const { emailVerified } = payload;
    const signUpEmail = yield select(getSignUpEmail);
    if (emailVerified) {
      const oAuthAppName = yield select(getOAuthAppName);

      // If the app's purpose is to grant permissions for an oAuth app
      // then the normal flow of loading conversations should be blocked
      if (oAuthAppName) {
        // The URL could be for a page other than oAuth, in which case this saga
        // will be triggered only after the user has signed in.
        // So once the user has signed in, we need to redirect them to
        // the oAuth permission granting page.
        if (location.type !== atypes.AUTHORIZE_OAUTH_APP_PAGE) {
          yield put({
            type: atypes.AUTHORIZE_OAUTH_APP_PAGE,
            meta: {
              query: location.query
            }
          });
        }

        const { uid } = yield select(getUser);

        // For the slack integration we need to map a slack workspace
        // with a Unifize Org, so we need the info of the user's orgs.
        yield put({ type: atypes.GET_ORGS_REQUEST, payload: { uid } });
      } else {
        const requestedChatRoom = yield select(getRequestedChatRoom);
        const previousTenantId = yield call(getStorage, "tenantId") ?? null;

        // Check if the user is switching between orgs with different
        // tenants and needs to be re-authenticated
        const isSwitchingOrg =
          previousTenantId !== authProvider.tenantId &&
          sessionStorage.getItem("switchingOrgs") !== "true";

        // Gets triggered only when user is switching orgs
        if (isSwitchingOrg) {
          // Log the user out if they are switching to an org with
          // different auth tenant configured
          yield put({
            type: atypes.LOGOUT_REQUEST,
            payload: { shouldPurge: false }
          });

          if (authProvider.tenantId !== null) {
            yield put({
              type: atypes.GET_AUTH_PROVIDER_SUCCESS,
              payload: {
                ...authProvider
              }
            });
          } else {
            // Populate the email value once the user is taken to sign in page
            // while switching orgs
            yield put({
              type: atypes.SET_SIGN_IN_EMAIL,
              payload: {
                email: payload.email
              }
            });
          }
        } else if (!R.isEmpty(requestedChatRoom.id)) {
          // Check if requestedChatroom belongs to the current org
          // If not then switch to that org and reload
          try {
            const chatroomDetails = yield call(
              chatroomApi.getChatroomByAddress,
              requestedChatRoom.id
            );
            const { orgId } = chatroomDetails;
            const orgInSession = sessionStorage.getItem("lastOrg");

            if (
              !R.isNil(orgInSession) &&
              orgId !== parseInt(orgInSession, 10)
            ) {
              yield put({
                type: atypes.SET_CURRENT_CHATROOM_REQUEST,
                payload: { id: requestedChatRoom.id },
                meta: {
                  query: {
                    templateId: requestedChatRoom.templateId
                  }
                }
              });
              sessionStorage.setItem("lastOrg", orgId);
              window.location.reload();
            }
          } catch (error) {
            console.error(error);
          }
        }

        if (authProvider.orgId) {
          // Setup the org for new user
          yield call(api.joinSsoOrg, payload.email, authProvider.orgId);
        }

        if (!isSwitchingOrg)
          // The normal app flow will get triggered with this action.
          yield put({ type: atypes.LOAD_CONVERSATIONS });
      }
    } else if (!emailVerified && R.isNil(signUpEmail)) {
      yield put({ type: atypes.SIGN_IN, payload: { page: "not-verified" } });
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchSignedIn(): any {
  yield takeEvery(atypes.SIGNED_IN, checkIfEmailVerified);
}

function* watchSignIn(): any {
  while (true) {
    const { payload, meta } = yield take(atypes.SIGN_IN);
    const { page } = payload;
    switch (page) {
      case "forgot":
        try {
          yield call(api.signOutUser);
        } catch (error) {
          console.error(error);
          console.log("User is already signedout");
        }
        break;
      case "verify-reset": {
        yield put({ type: atypes.VERIFY_RESET_CODE, payload: meta.query });
        break;
      }
      case "mobile": {
        if (!isMobile) {
          yield put({ type: atypes.SIGN_IN });
        }
      }
      // eslint-disable-next-line no-fallthrough
      default:
    }
  }
}

function* join(): any {
  try {
    const location = yield select(getLocation);
    const { email, orgId } = location.query;

    sessionStorage.setItem("inviteeOrg", orgId);

    yield take(atypes.AUTH_CHANNEL_EVENT_COMPLETE);

    if (email) {
      const auth = firebase.auth();
      const redirectedResult = yield call([auth, auth.getRedirectResult]);

      // If SSO authentication is incomplete or hasn't been triggered yet
      if (!redirectedResult?.user) {
        const authProvider = yield call(api.getAuthProvider, email);

        // Redirect the user to the SSO login if provider is present
        if (authProvider.tenantId && authProvider.provider) {
          yield put({
            type: atypes.GET_AUTH_PROVIDER_SUCCESS,
            payload: {
              ...authProvider
            }
          });
        }
      }
    } else {
      yield put({
        type: atypes.SIGN_IN,
        payload: {}
      });
    }
  } catch (e) {
    // This is the expected behaviour for most of the cases in this flow.
    // The `getAuthProvider' function throws an exception if password is
    // yet set.
    if (e.status !== 403) {
      yield put({
        type: atypes.JOIN_ORG_FAILURE,
        payload: e
      });
    }
  }
}

function* watchJoin(): any {
  yield takeEvery(atypes.JOIN, join);
}

function* takeToSignIn(): any {
  yield take(atypes.SIGNED_IN);
  while (true) {
    const { payload } = yield take(atypes.SIGNED_OUT);
    // Remove the lastOrg from sessionStorage only if user is not
    // between switching orgs
    if (!payload.isSwitchingOrg) {
      sessionStorage.removeItem("lastOrg");
    }
    const location = yield select(state => state.location);
    if (location.type === atypes.ORG_CLONE_PAGE) {
      continue;
    } else {
      yield put({ type: atypes.SIGN_IN });
    }
  }
}

function* watchHome(): any {
  while (true) {
    yield put({ type: atypes.CLEAR_SIGN_UP_EMAIL });
    yield take(atypes.HOME);
    const { uid } = yield select(getUser);
    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
    }
  }
}

function* logout({ payload }: Action): any {
  try {
    try {
      localforage.clear();
    } catch (error) {
      console.error(error);
      console.error("Unable to clear storage when user logs out");
    }

    yield call(api.signOutUser);
    if (payload.shouldPurge) {
      // $FlowFixMe
      yield (persistor || {}).purge();
      // Clear lastOrg from sessionStorage
      sessionStorage.clear();
      yield put({ type: atypes.LOGOUT_SUCCESS });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: atypes.LOGOUT_FAILURE });
  }
}

function* watchLogout(): any {
  yield takeEvery(atypes.LOGOUT_REQUEST, logout);
}

function* loginWithCustomToken(action: Action) {
  const { customToken } = action.payload;
  try {
    yield call(api.signInWithCustomAuthToken, customToken);
    yield put({
      type: atypes.LOGIN_WITH_TOKEN_SUCCESS
    });
  } catch (error) {
    yield put({
      type: atypes.LOGIN_WITH_TOKEN_FAILURE,
      payload: error
    });
  }
}

function* watchLoginInWithToken(): any {
  yield takeEvery(atypes.LOGIN_WITH_TOKEN_REQUEST, loginWithCustomToken);
}

function* storeInitialChatrooms({ payload }: Action): any {
  const chatrooms = payload.initChatrooms || [];

  const deletedRooms = [];
  const chatroomData = {};
  const lastMessage = {};

  let latestUpdatedAt = null;

  for (const room of chatrooms) {
    if (room.deleted) {
      deletedRooms.push(room.id);
      continue;
    }

    lastMessage[room.id] = {
      text: room.lastMessage,
      author: room.lastMessageAuthor
    };

    if (room.updatedAt > latestUpdatedAt || !latestUpdatedAt) {
      latestUpdatedAt = moment(room.updatedAt)
        .seconds(0)
        .milliseconds(0)
        .toISOString();
    }

    chatroomData[room.id] = {
      ...R.pickAll(chatroomMetaData, room),
      autoNo: room.autoNo ? `${room.autoNo}` : null,
      id: `${room.id}`,
      createdAt: room.createdAt
        ? moment(room.createdAt).seconds(0).milliseconds(0).toISOString()
        : null,
      updatedAt: room.updatedAt
        ? moment(room.updatedAt).seconds(0).milliseconds(0).toISOString()
        : null,
      dueDate: room.dueDate ? getDueDate(room.dueDate) : null
    };
  }

  yield put({
    type: atypes.LOAD_CHATROOMS_SUCCESS,
    payload: {
      chatRooms: chatroomData,
      lastMessage
    }
  });
}

function* watchStoreInitialChatrooms(): any {
  yield takeLatest("STORE_INIT", storeInitialChatrooms);
}

function* authenticateWithServer({ orgId }: Object): any {
  try {
    const tenantId = yield call(getStorage, "tenantId");
    // $FlowFixMe - false alarm
    const { phoneNumber, department, departmentName, chatrooms } = yield call(
      api.bootstrap,
      tenantId ?? ""
    );

    const currentUserId = yield select(getCurrentUserId);

    // Update the store with some chatrooms
    if (BETA_TESTERS.includes(currentUserId)) {
      yield put({
        type: "STORE_INIT",
        payload: {
          initChatrooms: chatrooms
        }
      });
    }
    yield put({
      type: atypes.SERVER_AUTH_SUCCESS,
      payload: { phoneNumber, department, departmentName }
    });

    window.currentOrgId = orgId;
  } catch (error) {
    console.error("Error authenticating with the server:", error);
    yield put({
      type: atypes.SERVER_AUTH_FAILURE,
      payload: { error }
    });
  }
}

function* watchAuthenticateWithServer(): any {
  yield takeEvery(atypes.SERVER_AUTH_REQUEST, authenticateWithServer);
}

function* joinOrg({ payload }: Action) {
  try {
    const location = yield select(getLocation);

    const orgId = parseInt(location.query.orgId, 10);
    const { authCode } = location.query;

    const { customToken } = yield call(api.join, {
      orgId,
      authCode,
      email: payload.email,
      password: payload.password,
      displayName: payload.displayName
    });

    yield call(api.signInWithCustomAuthToken, customToken);

    yield put({
      type: atypes.UPDATE_DISPLAY_NAME_REQUEST,
      payload: { displayName: payload.displayName }
    });

    if (payload.profilePicture) {
      yield put({
        type: atypes.UPLOAD_PROFILE_PICTURE_REQUEST,
        payload: { file: payload.profilePicture }
      });
    }

    yield all([
      race([
        take(atypes.UPDATE_DISPLAY_NAME_SUCCESS),
        take(atypes.UPDATE_DISPLAY_NAME_FAILURE)
      ]),
      payload.profilePicture &&
        race([
          take(atypes.UPLOAD_PROFILE_PICTURE_SUCCESS),
          take(atypes.UPLOAD_PROFILE_PICTURE_FAILURE)
        ])
    ]);

    const token = yield call(api.getAuthToken, {});
    sessionStorage.setItem("authToken", token);

    yield call(api.authenticate, token);

    // Authenticate with API
    yield put({
      type: atypes.SERVER_AUTH_REQUEST,
      payload: {
        token,
        orgId
      }
    });

    // Ask for additional details after
    // SERVER_AUTH_SUCCESS
    yield take(atypes.SERVER_AUTH_SUCCESS);
    yield put({ type: atypes.CLEAR_SIGNUP_DETAILS });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.JOIN_ORG_FAILURE,
      payload: error
    });
  }
}

function* watchJoinOrg(): any {
  yield takeEvery(atypes.JOIN_ORG_REQUEST, joinOrg);
}

function* watchRedirectToMobile(): any {
  const { uid } = yield select(getUser);
  while (true) {
    yield take(atypes.REDIRECT_TO_MOBILE_APP);
    if (!isMobile) {
      yield put({ type: atypes.GET_ORGS_REQUEST, payload: { uid } });
    }
  }
}

function* switchUser(action: Action): any {
  const { uid } = action.payload;
  try {
    const customToken = yield call(api.loginAs, uid);

    yield put({
      type: atypes.LOGIN_WITH_TOKEN_REQUEST,
      payload: { customToken }
    });

    // Wait for LOGIN_WITH_TOKEN_SUCCESS
    yield take(atypes.LOGIN_WITH_TOKEN_SUCCESS);
    window.location.reload();
  } catch (error) {
    toast.error(`View as someone failed`);
    yield put({
      type: atypes.LOGIN_AS_FAILURE,
      payload: error
    });
  }
}

function* watchLoginAsRequest(): any {
  yield takeEvery(atypes.LOGIN_AS_REQUEST, switchUser);
}

function* clearSignupDetails(): any {
  yield put({ type: atypes.CLEAR_SIGN_UP_EMAIL });
  yield put({ type: atypes.EMAIL_VERIFIED });

  // Set Last Org
  yield put({ type: atypes.LOAD_CONVERSATIONS });
}

function* watchClearSignupDetails(): any {
  yield takeEvery(atypes.CLEAR_SIGNUP_DETAILS, clearSignupDetails);
}

function* setRequestedPage(): any {
  try {
    const requstedPage = yield select(getRequestedPage);

    switch (requstedPage.page) {
      case "contacts":
        yield put({
          type: atypes.SET_CONTACTS_REQUEST,
          payload: {
            query: requstedPage.query
          }
        });
        break;
      case "groups":
        yield put({
          type: atypes.SET_GROUPS_REQUEST,
          payload: {},
          meta: {
            query: requstedPage.query
          }
        });
        break;
      case "file":
        yield put({
          type: atypes.SET_FILE_REQUEST,
          payload: {
            query: requstedPage.query
          }
        });
        break;
      case "process":
        yield put({
          type: atypes.SET_PROCESS_REQUEST,
          payload: {},
          meta: {
            query: requstedPage.query
          }
        });
        break;
      case "forms":
        yield put({
          type: atypes.SET_FORMS_REQUEST,
          payload: {},
          meta: {
            query: requstedPage.query
          }
        });
        break;
      case "dashboard":
        yield put({
          type: atypes.SET_DASHBOARD_REQUEST,
          payload: {
            id: requstedPage.query.id
          }
        });
        break;
      case "orgSettings":
        yield put({
          type: atypes.SET_ORG_SETTINGS_REQUEST,
          payload: {
            id: requstedPage.query.id
          }
        });
        break;
      case "report":
        yield put({
          type: atypes.SET_REPORTS_REQUEST,
          payload: {
            id: requstedPage.query.id
          },
          meta: {
            query: requstedPage.query.filter
          }
        });
        break;
      case "user-analytics":
        yield put({
          type: atypes.SET_USER_ANALYTICS_REQUEST,
          payload: {},
          meta: {
            query: requstedPage.query
          }
        });
        break;
      case "settings":
        yield put({
          type: atypes.SET_SETTINGS_REQUEST,
          payload: {
            query: requstedPage.query
          }
        });
        break;
      default:
    }
  } catch (error) {
    console.error("Error setting page", error);
  }
}

function* watchRequestPage(): any {
  yield takeEvery(atypes.SET_CURRENT_CHATROOM_SUCCESS, setRequestedPage);
}

function* watchRequestHomePage(): any {
  yield takeEvery(atypes.SET_HOME_SCREEN_REQUEST, setRequestedPage);
}

function* userSignup({ payload }: Action): any {
  try {
    const response = yield call(api.signup, payload.email);
    yield put({
      type: atypes.USER_SIGNUP_SUCCESS,
      payload: {
        email: payload.email,
        code: response.code
      }
    });
  } catch (error) {
    if (error.status === 409) {
      yield put({
        type: atypes.SIGN_IN,
        payload: {
          message:
            "Looks like you already have an account. Please enter your password to sign in."
        }
      });
    }

    yield put({
      type: atypes.USER_SIGNUP_FAILURE,
      payload: {
        error: error.message
      }
    });
  }
}

function* watchUserSignup(): any {
  yield takeEvery(atypes.USER_SIGNUP_REQUEST, userSignup);
}

function* changePassword({ payload }: Action): any {
  try {
    yield call(api.changePassword, payload.newPassword);
    yield put({
      type: atypes.CHANGE_PASSWORD_SUCCESS,
      payload: {}
    });

    toast.success("Your password has been changed successufully.");
  } catch (error) {
    toast.error("Unable to change password.");
    yield put({
      type: atypes.CHANGE_PASSWORD_FAILURE,
      payload: {
        error: error
      }
    });
  }
}

function* watchChangePassword(): any {
  yield takeEvery(atypes.CHANGE_PASSWORD_REQUEST, changePassword);
}

function* sendVerificationEmail({ payload }: Action): any {
  try {
    yield call(api.sendVerificationEmail, payload.email);

    // When the user tries to resend the inivitation email don't redirect to the verification-mail route.
    if (!payload.resendEmailInvite) {
      yield put({
        type: atypes.SIGN_IN,
        payload: { page: "verification-mail", email: payload.email }
      });
    }

    yield put({
      type: atypes.SEND_VERIFICATION_EMAIL_SUCCESS,
      payload: {}
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.SEND_VERIFICATION_EMAIL_FAILURE,
      payload: error
    });
    toast.error("Too many attempts, try after sometime");
  }
}

function* watchSendEmailVerification(): any {
  yield takeEvery(
    atypes.SEND_VERIFICATION_EMAIL_REQUEST,
    sendVerificationEmail
  );
}

function* getAuthProvider({ payload }: Action): any {
  try {
    Sentry.addBreadcrumb({
      category: "message",
      message: "Fetching auth provider",
      level: "info",
      data: {
        email: payload.email
      }
    });
    const authProvider = yield call(api.getAuthProvider, payload.email);

    Sentry.captureMessage("Auth provider details fetched", {
      level: "info",
      extra: {
        response: authProvider
      }
    });

    // Update last org value if authProvider is present
    if (authProvider.provider !== null) {
      sessionStorage.setItem("lastOrg", authProvider.orgId);
    }

    yield put({
      type: atypes.GET_AUTH_PROVIDER_SUCCESS,
      payload: {
        ...authProvider
      }
    });
  } catch (error) {
    if (error.status === 403) {
      yield put({
        type: atypes.SEND_VERIFICATION_EMAIL_REQUEST,
        payload: { email: payload.email }
      });
    }

    yield put({
      type: atypes.GET_AUTH_PROVIDER_FAILURE,
      payload: error
    });
  }
}

function* watchAuthProviderRequest(): any {
  yield takeEvery(atypes.GET_AUTH_PROVIDER_REQUEST, getAuthProvider);
}

function* signInUserWithAuthProvider({ payload }: Action): any {
  try {
    if ((payload.tenantId || payload["tenant-id"]) && payload.provider) {
      Sentry.addBreadcrumb({
        category: "message",
        message: "Navigating to SSO page",
        level: "info",
        data: {
          tenantId: payload.tenantId || payload["tenant-id"]
        }
      });

      yield call(api.signInUserWithAuthProvider, {
        ...payload,
        tenantId: payload.tenantId || payload["tenant-id"]
      });

      Sentry.captureMessage("SSO Auth Successful", {
        level: "info"
      });

      // Wait for some user data from Firebase
      yield take(atypes.AUTH_CHANNEL_EVENT_COMPLETE);
    }
  } catch (error) {
    yield put(authenticationFailed(error));
  }
}

function* watchAuthProviderSuccess(): any {
  yield takeEvery(atypes.GET_AUTH_PROVIDER_SUCCESS, signInUserWithAuthProvider);
}

function* redirectToSignInPage(): any {
  try {
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    const nickName: string = queryParams.get("slug") || "";

    Sentry.addBreadcrumb({
      category: "message",
      message: "/sso-redirect flow",
      level: "info",
      data: {
        nickName,
        queryParams,
        url
      }
    });

    if (!nickName || queryParams.get("redirected") === "true") {
      Sentry.captureMessage("Redirecting back to /signin", {
        level: "info"
      });
      return yield put({
        type: atypes.SIGN_IN
      });
    }

    queryParams.append("redirected", "true");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, "", newUrl);

    Sentry.captureMessage("Redirecting to auth provider", {
      level: "info"
    });

    const response = yield call(api.getAuthProviderFromNickname, nickName);

    if (response?.orgId) {
      Sentry.captureMessage("SSO Authentication successful", {
        level: "info"
      });
      sessionStorage.setItem("lastOrg", response.orgId);
      yield put({
        type: atypes.GET_AUTH_PROVIDER_SUCCESS,
        payload: response
      });
    } else {
      Sentry.captureMessage(
        "SSO not configured for this org, taking user back to /signin",
        {
          level: "info"
        }
      );
      yield put({
        type: atypes.SIGN_IN
      });
      toast.error("SSO is not configured for this org or Invalid org slug");
    }
  } catch (err) {
    console.error(err);
  }
}

function* watchSSORedirect(): any {
  yield takeEvery(atypes.SSO_REDIRECT, redirectToSignInPage);
}

export { monitorAuthSate };

export default [
  watchRequestHomePage(),
  watchUserSignup(),
  watchSignIn(),
  watchSignedIn(),
  watchVerifyResetCode(),
  watchForgotPasswordRequest(),
  watchResetPasswordRequest(),
  watchAuthenticationRequest(),
  watchAuthenticateWithServer(),
  watchHome(),
  watchLogout(),
  watchJoinOrg(),
  watchRedirectToMobile(),
  watchLoadConversations(),
  watchLoginInWithToken(),
  takeToSignIn(),
  watchLoginAsRequest(),
  watchRequestPage(),
  watchClearSignupDetails(),
  watchChangePassword(),
  watchAuthProviderRequest(),
  watchAuthProviderSuccess(),
  watchJoin(),
  watchSendEmailVerification(),
  watchStoreInitialChatrooms(),
  watchSSORedirect()
];
