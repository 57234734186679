// @flow

import * as R from "ramda";
import { filterByOverdue } from "src/utils/filters";
import type {
  UnifizeChatRoom,
  RoomId,
  TemplateStatus,
  Workflow
} from "src/types";

/**
 * Check whether the given argument is a valid Unifize chatroom.
 * @param {object | null} chatroom - The chatroom to check.
 */
export const isValidChatroom = (chatroom: ?Object = {}) =>
  // $FlowFixMe
  R.all(R.has(R.__, chatroom))([
    "status",
    "id",
    "address",
    "title",
    "type",
    "seqNo"
  ]);

// $FlowFixMe
export const sortByAutoNumber = R.sort<UnifizeChatRoom>((roomA, roomB) => {
  if (!roomA.seqNo || !roomB.seqNo) return Number(roomA.id) - Number(roomB.id);

  // If autoNo is not present or if the chatroom has multiple versions
  // (contains "/") rely on the seqNo for sorting instead of using the
  // autoNo
  if (
    !roomA.autoNo ||
    !roomB.autoNo ||
    (roomA.autoNo && !roomA.autoNo.includes("/")) ||
    (roomB.autoNo && !roomB.autoNo.includes("/"))
  )
    return Number(roomA.seqNo) - Number(roomB.seqNo);

  let [seqA, versionA] = roomA.autoNo.split("/").map(Number);
  let [seqB, versionB] = roomB.autoNo.split("/").map(Number);

  if (seqA !== seqB) return seqA - seqB;

  return versionA - versionB;
});

// $FlowFixMe
export const reverseSortById = R.sort<UnifizeChatRoom>(
  (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
);

/**
 * Resolve  chatroom ids from linked field chatroom map
 * @param {object} roomsById - chatroom map
 * @returns {array} - returns an array of room ids
 */
export function extractChatroomIds(roomsById: Object) {
  const chatroomIds: Array<RoomId> = [];
  for (const key in roomsById) {
    chatroomIds.push(roomsById[key].chatroom.id);
  }
  return chatroomIds;
}

/**
 * Returns a function that checks if a chatroom title matches a given text,
 * including template title and sequence number.
 * @param {string} text - The text to search for within the chatroom's title.
 * @param {Workflow} workflows - The map of workflow templates used to
 * extract titles for ChatRooms.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function to
 * evaluate if the chatroom title matches the provided text.
 */
export const containsTitle =
  (text: string, workflows: Workflow) => (chat: UnifizeChatRoom) => {
    if (chat) {
      const processTitle = chat.templateId
        ? workflows[`${chat.templateId}`] || ""
        : "";

      const seqNo = chat.seqNo || "";
      const title = chat.title || "";

      return (
        R.includes(
          R.toLower(text),
          `${R.toLower(processTitle)} ${seqNo}:${R.toLower(title)}`
        ) ||
        R.includes(
          R.toLower(text),
          `${R.toLower(processTitle)} #${seqNo}:${R.toLower(title)}`
        ) ||
        R.includes(
          R.toLower(text),
          `${R.toLower(processTitle)}#${seqNo}:${R.toLower(title)}`
        )
      );
    }
    return false;
  };

/**
 * Returns if a chatroom is cancelled or not.
 * @param {UnifizeChatRoom} chat - ChatRoom
 * @returns {boolean} - returns boolean.
 */
export const notCanceled = (chat: UnifizeChatRoom) => {
  if (chat) {
    return !chat.canceled;
  }
  return false;
};

/**
 * Returns a function that checks if a chatroom matches the specific workflow.
 * @param {string} workflow - Workflow ID
 * @returns {function(UnifizeChatRoom): boolean} - returns predicate function.
 */
export const containsWorkflow =
  (workflow?: string) => (chat: UnifizeChatRoom) => {
    if (workflow) {
      return (
        parseInt(workflow, 10) === chat.templateId && chat.type === "workflow"
      );
    }
    return true;
  };

/**
 * Returns a function that checks if a chatroom matches the specific type.
 * @param {string} type - The type of chatroom to check against.
 *  * @returns {function(ChatRoom): boolean} - Returns a predicate function
 * to evaluate if the chatroom matches the type.
 */
export const containsType = (type: string) => (chat: UnifizeChatRoom) => {
  return (
    (chat.type !== "direct" && type === chat.type && type !== undefined) ||
    (chat.type !== "direct" && type === undefined)
  );
};

/**
 * Returns a function that checks if a chatroom matches the overdue condition.
 * @param {boolean} overdue - Indicates whether to filter for overdue ChatRooms.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function
 * to evaluate if the chatroom meets the overdue condition.
 */
export const containsOverdue =
  (overdue: boolean) => (chat: UnifizeChatRoom) => {
    if (overdue)
      return chat
        ? filterByOverdue({ overdue })(chat) &&
            chat.active !== false &&
            !chat.canceled
        : false;
    return true;
  };

/**
 * Returns a function that checks if a chatroom matches the completed condition.
 * @param {boolean} completed - Indicates whether to filter for completed ChatRooms.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function to
 * evaluate if the chatroom meets the completed condition.
 */
export const containsCompleted =
  (completed: boolean): Function =>
  (chat: UnifizeChatRoom): boolean => {
    if (completed) return chat && (chat.active || chat.canceled || false);
    return true;
  };

/**
 * Returns a function that checks if a chatroom matches the pending condition.
 * @param {boolean} pending - Indicates whether to filter for pending ChatRooms.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function to
 * evaluate if the chatroom meets the pending condition.
 */
export const containsPending =
  (pending: boolean) => (chat: UnifizeChatRoom) => {
    if (pending) return chat ? chat.active !== false && !chat.canceled : false;
    return true;
  };

/**
 * Returns a function that checks if a chatroom is owned by a specific user.
 * @param {boolean} owner - Indicates whether to filter for ownership.
 * @param {string} uid - The user ID to check against the chatroom owner.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function
 * to evaluate if the chatroom is owned by the specified user.
 */
export const containsOwner =
  (owner: boolean, uid: string) => (chat: UnifizeChatRoom) => {
    if (owner) return chat ? chat.owner === uid : false;
    return true;
  };

/**
 * Returns a function that checks if a chatroom is archived or should be filtered
 * out based on archived status.
 * @param {?boolean} shouldFilterOutArchived - Indicates whether to filter out
 * archived ChatRooms.
 * @param {TemplateStatus} templateStatus - The status of templates, used to
 * determine auto-archive status.
 * @returns {function(UnifizeChatRoom): boolean} - Returns a predicate function to
 * evaluate if the chatroom should be filtered based on its archived status.
 */
export const containsArchived =
  (shouldFilterOutArchived: ?boolean, templateStatus: TemplateStatus) =>
  (chat: UnifizeChatRoom) => {
    if (shouldFilterOutArchived) {
      // If chatroom belongs to a process, get auto archive
      const chatroomAutoArchived = chat.templateId
        ? templateStatus[`${chat.templateId}`]?.[`${chat.status}`]
            ?.archiveMode === "archive"
        : false;
      const manuallyArchived = chat.archived;
      const archived = R.isNil(manuallyArchived)
        ? chatroomAutoArchived
        : manuallyArchived;

      return chat ? !archived : false;
    }

    return true;
  };

/**
 * Checks whether the filter that the user is trying to delete is a
 * custom filter and is currently applied on the conversation list
 * @param {object} currentFilter - details of the currently applied
 * filter on conversation list
 * @param {string} filterToBeDeleted - name of the filter that the user
 * is trying to delete
 * @returns {boolean} - whether or not the current filter should be
 * deleted
 */
export const isSavedFilterApplied = (
  currentFilter: Object,
  filterToBeDeleted: string
) => {
  return (
    currentFilter.name === filterToBeDeleted && currentFilter.isSavedFilter
  );
};
