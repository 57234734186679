// @flow

import React from "react";
import Link from "redux-first-router-link";
import { useSelector } from "react-redux";
import { HStack, Text } from "@chakra-ui/react";
import Icon from "src/icons";
import * as atypes from "src/constants/actionTypes";
import { getTemplateId } from "src/reducers";
import { componentPermissions } from "src/constants/roleManagement";
import useComponentPermission from "src/hooks/useComponentPermission";

import type { RoomId } from "src/types";
import * as styles from "./styles";
import { active } from "src/styles/constants/colors";

type Props = {
  roomId: RoomId
};

const ProcessBuilder = ({ roomId }: Props) => {
  const templateId = useSelector(({ app }) => getTemplateId(app, `${roomId}`));
  const isProcessSettingsAllowed = useComponentPermission(
    componentPermissions.processSettings
  );

  if (!isProcessSettingsAllowed) return null;
  return (
    <Link
      to={{
        type: atypes.EDIT_PROCESS_REQUEST,
        payload: { id: templateId },
        meta: {
          query: {
            tab: "Checklist"
          }
        }
      }}
      style={{ color: "initial", width: "100%" }}
    >
      <HStack sx={styles.MenuItem} tabIndex={0}>
        <Icon
          type="gear"
          variant="outlined"
          color={active}
          width="1rem"
          height="1rem"
        />
        <Text>Process Builder</Text>
      </HStack>
    </Link>
  );
};

export default ProcessBuilder;
