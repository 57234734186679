// @flow

import React, { useRef } from "react";
import { Portal } from "@chakra-ui/react";
import {
  Overlays,
  MainWrapper,
  Header,
  HeaderText,
  IconWrapper,
  CloseWrapper,
  CanvasWrapper,
  Button,
  MessageText
} from "./styles";
import Icons from "src/icons";

type Props = {
  setImageModal: Function,
  canvasRef: any,
  handleUpload: Function,
  setIsInputFocused: Function
};

const ImagePasteModal = ({
  setImageModal,
  canvasRef,
  handleUpload,
  setIsInputFocused
}: Props) => {
  const modalRef = useRef(null);

  const close = () => {
    setImageModal(false);
    setIsInputFocused(false);
  };

  return (
    <Portal>
      <Overlays onClick={close}>
        <MainWrapper ref={modalRef}>
          <Header>
            <IconWrapper>
              <Icons type="imageStack" />
              <HeaderText>Upload image</HeaderText>
            </IconWrapper>
            <CloseWrapper>
              <Icons type="close" />
            </CloseWrapper>
          </Header>
          <MessageText>Are you sure you want to upload this image?</MessageText>
          <CanvasWrapper>
            <canvas ref={canvasRef} />
          </CanvasWrapper>
          <Button onClick={handleUpload}>Upload</Button>
        </MainWrapper>
      </Overlays>
    </Portal>
  );
};

export default ImagePasteModal;
