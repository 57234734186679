// @flow

export const unifizeBlue = "#2e4c68";
export const primary = "#2A2A2A";
export const brightLight = "#ffffff";
export const danger = "#ff0000";
export const muted = "rgba(136, 136, 136, 0.1)";
export const secondary = "#888888";
export const ternary = "#B3B3B3";
export const active = "#1581ba";
export const success = "#0abc8a";
export const checked = "#4cd864";
export const currentUser = "#e7ffec";
export const currentUserHighlight = "#cff5d7";
export const oblique = "#7d9479";
export const warning = "#f1a208";
export const highlight = "#e7f2f8";
export const overlay = "rgba(0, 0, 0, 0.3)";
export const modal = "rgba(42,42,42,0.3)";
export const sideNav = "#465c70";
export const backgroundText = "#a9bbcb";
export const outline = "#7fc3eb";
export const appBackground = "#f3f8fb";
export const disabled = "#bbbbbb";
export const activeFilter = "#2f80ed";
export const close = "#fa5e5b";
export const dark = "#000000";
export const selected = "#67CBFF";
export const purpleLighter = "#F6F0FD";
export const darkGreen = "#16C98D";
export const lightBlue = "#EBF8FF";
export const lightBlue25 = "#F5FBFF";
export const amber = "#FFC700";
export const paleBlue = "#DEEAEE";
export const salmonPink = "#F56565";
export const tooltip = "#171923";
export const blue200 = "#90CDF4";
export const lightBlue600 = "#1581BA";

// Greys
export const grey0 = "#f5f6f7";
export const grey1 = "#f2f2f2";
export const grey2 = "#E9EBEC";
export const grey3 = "#e8ecef";
export const grey4 = "#dddddd";
export const grey5 = "#989ca6";
export const grey6 = "#cccccc";
export const grey7 = "#e6e6e6";
export const grey8 = "#2a2e36";
export const grey9 = "#d3d3d3";
export const grey10 = "#f3f3f3";
export const grey11 = "#f1f5fa";
export const grey12 = "#b8d9ea";
export const grey13 = "#ecf3fa";
export const grey14 = "#f4f8fc";
export const grey15 = "rgba(232, 240, 248, 0.5)";
export const grey16 = "rgba(21, 129, 186, 0.05)";
export const grey17 = "rgba(21, 129, 186, 0)";
export const grey18 = "#979797";
export const grey19 = "#f7f7f7";
export const grey20 = "#fafafa";
export const grey21 = "#4b4b4b";
export const grey22 = "#E0F5F5";
export const grey25 = "#EBF5FA";
export const grey26 = "#E3F1DF";
export const grey27 = "#173630";
export const grey28 = "#f4f6f8";
export const grey29 = "#828282";
export const grey30 = "#E0E0E0";
export const grey31 = "#757575";
export const grey32 = "#EEF2F7";
export const grey33 = "#EDF2F7";
export const grey34 = "#CBD5E0";
export const grey35 = "#E2E8F0";
export const grey36 = "#4A5568";
// Grey for extra vertical space in manage view
export const grey37 = "#F7FAFC80";
export const grey38 = "#A0AEC0";
export const grey39 = "#667085";
export const grey40 = "#D0D5DD";
export const grey41 = "#EAECF0";
export const grey42 = "#98A2B3";
export const grey43 = "#FCFCFD";
export const grey44 = "#718096";
// Extra grey added as it's present in the new design system
export const grey45 = "#1D2939";

// Blue
export const blue0 = "#EBFDFF";
export const blue1 = "#47BFFF";

// Black
export const black0 = "#212121";

// chat blocks
export const purpleLight = "#E3D0FF";
export const purple = "#9C6ADE";
export const purpleDark = "#50248F";
export const sky = "#DFE3E8";
export const skyDark = "#C4CDD5";
export const skyLighter = "#F9FAFB";
export const indigoLight = "#B3BCF5";
export const indigo = "#5C6AC4";
export const indigoDark = "#202E78";
export const tealLight = "#B7ECEC";
export const teal = "#47C1BF";
export const tealDark = "#00848E";
export const blueLight = "#B4E0FA";
export const blue = "#2F80ED";
export const blue500 = "#3182CE";
export const blue300 = "#63B3ED";
export const blueDark = "#084E8A";
export const greenLight = "#BBE5B3";
export const greenDark = "#108043";
export const green = "#50B83C";
export const greenLight700 = "#64DD17";
export const green700 = "#00C853";
export const green500 = "#38A169";
export const yellowLight = "#FFEA8A";
export const yellow = "#EEC200";
export const yellowDark = "#8A6116";
export const amberA400 = "#FFC400";
export const orangeLight = "#FFC58B";
export const orange = "#F49342";
export const orange300 = "#F6AD55";
export const orangeDark = "#C05717";
export const orangeRed = "#E53D01";
export const redLight = "#FA9A88";
export const mildRed = "#FBEAE5";
export const redDark = "#BF1D08";
export const red400 = "#583C35";
export const red500 = "#E53E3E";
export const red600 = "#C53030";
export const redA400 = "#FF1744";
export const inkLightest = "#919EAB";
export const inkLighter = "#637381";
export const inkLight = "#454F5B";
export const inkDark = "#475467";
export const ink = "#212B36";
export const pink300 = "#F687B3";
export const purple50 = "#FAF5FF";
export const black300 = "#020202";

export const whiteAlpha200 = "rgba(255, 255, 255, 0.08)";
export const blackAlpha100 = "rgba(0, 0, 0, 0.1)";
export const blackAlpha500 = "rgba(0, 0, 0, 0.36)";
export const blackAlpha700 = "rgba(0, 0, 0, 0.64)";

// form colors
export const indigoLighter = "#F4F5FA";
export const yellowLighter = "#FCF1CD";

// Color palette for charts
export const chartColors =
  "E3D0FF,B3BCF5,B4E0FA,B7ECEC,BBE5B3,FFEA8A,FFC58B,FA9A88,9C6ADE,5C6AC4,006FBB,47C1BF,50B83C,EEC200,F49342,DE3618,F6F0Fd,F4F5FA,EBF5FA,E0F5F5,E3F1DF,FCF1CD,FCEBDB,FBEAE5,50248F,202E78,084E8A,00848E,108043,8A6116,C05717,BF1D08,230051,000639,001429,003135,173630,573B00,4A1504,330202,50495A,3e4155,3E4E57,405352,414F3E,595130,594430,583C35,50ff37,4200df,fff11a,000084,009700,f952ff,68c851,d400a5,00ffc7,f20000,00ffff,ac0000,00ffff,e60069,34f1de,150068,ffa225,007bff,e98e00,0076f6,ffb33c,004cb7,ff813c,004bac,fa5b32,00f1ff,8c0000,14f0ff,760000,2bf0e4,730000,00d1ff,700000,00bcfe,a86e00,e97aff,246902,ff7ee9,003c00,ae48b8,6ab96a,78004e,94ce83,5f0043,f6ed95,002151,ff9851,00abff,6c0000,aafdff,530000,00c4c0";
