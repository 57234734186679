// @flow

const options = [
  { key: "seqNo", active: true },
  { key: "title", active: true },
  { key: "status", active: true },
  { key: "owner", active: true },
  { key: "dueDate", active: true },
  { key: "members", active: true },
  { key: "priority", active: true },
  { key: "age", active: true },
  { key: "parent", active: false },
  { key: "childCount", active: false },
  { key: "createdAt", active: false },
  { key: "updatedAt", active: false },
  { key: "completedAt", active: false },
  { key: "completedBy", active: false },
  { key: "creator", active: false },
  { key: "description", active: false }
];

export const size = {
  seqNo: "50px",
  title: "280px",
  priority: "80px",
  status: "110px",
  dueDate: "120px",
  parent: "220px",
  childCount: "80px",
  createdAt: "160px",
  updatedAt: "160px",
  completedAt: "160px",
  completedBy: "160px",
  age: "90px",
  creator: "160px",
  owner: "160px",
  members: "200px",
  description: "230px"
};

export const checklistFieldSizeMap = {
  text: "230px",
  number: "100px",
  date: "200px",
  user: "200px",
  conversation: "220px",
  childConversation: "220px",
  select: "120px",
  pdf: "230px",
  form: "220px",
  file: "230px",
  revision: "150px",
  link: "250px",

  // Old checklist types
  chatPickList: "220px",
  workflow: "220px",
  task: "220px",
  group: "220px",
  approval: "220px"
};

export const manageViewFieldWidthMap = {
  ...size,
  ...checklistFieldSizeMap
};

export const omitedFields = ["section", "subSection"];
export default options;
