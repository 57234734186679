// @flow

import * as R from "ramda";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import {
  all,
  call,
  put,
  takeEvery,
  take,
  takeLatest,
  select,
  throttle,
  delay,
  fork,
  cancel
} from "redux-saga/effects";

import { capitalize } from "../utils";
import connection, { rsf } from "src/db";
import * as atypes from "src/constants/actionTypes";
import * as chatroom from "src/api/chatroom";
import getAppState, {
  getLastOrg,
  getChatroom,
  getUser,
  getLocation,
  getCurrentChatroom,
  getCurrentUserId,
  getWorkflowsById
} from "src/selectors";
import { loadTyping } from "src/actions/chatroom";
import {
  getChatrooms,
  getLocationType,
  getUniqueValues,
  getMessageText,
  getConversationModalId
} from "src/reducers";
import {
  sortByAutoNumber,
  reverseSortById,
  notCanceled,
  containsType,
  containsOwner,
  containsTitle,
  containsOverdue,
  containsArchived,
  containsWorkflow,
  containsCompleted,
  containsPending
} from "src/utils/chatroom";
import { showView } from "src/actions";
import chatroomMetaData from "src/constants/chatroomMetaData";
import type { Action } from "src/types";

function* syncUserChatroomAttributes({ payload }: Action) {
  const { last: orgId } = payload;
  const { uid } = yield select(getUser);

  try {
    const type = getLocationType(yield select(getLocation));

    if (type === atypes.SIGN_IN) {
      yield put({
        type: atypes.LOAD_DEFAULT_CHATROOM,
        payload: {}
      });
    }

    const channel = rsf.firestore.channel(
      connection()
        .collection(`userData/${uid}/chatrooms`)
        .where("orgId", "==", orgId)
    );

    while (true) {
      const lastRead = [];
      const count = [];
      const favourites = [];
      const dismissed = {};

      const snapshot = yield take(channel);

      for (const { doc } of snapshot.docChanges()) {
        const roomId = doc.id;
        const {
          lastRead: chatroomLastRead,
          favourite: chatroomFavourite,
          count: messageCount,
          dismissed: chatroomDismissed
        } = doc.data();

        const lastReadTime = chatroomLastRead
          ? chatroomLastRead.toDate()
          : null;

        if (lastReadTime) {
          lastRead.push([roomId, lastReadTime]);
        }

        if (messageCount) {
          count.push([roomId, messageCount]);
        }

        if (chatroomFavourite) {
          favourites.push(roomId);
        }

        if (chatroomDismissed) {
          dismissed[roomId] = chatroomDismissed;
        }
      }

      yield put({
        type: atypes.SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS,
        payload: {
          lastRead,
          count,
          favourites,
          dismissed
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_USER_CHATROOM_ATTRIBUTES_FAILURE,
      payload: { error }
    });
  }
}

function* watchSyncUserChatroomAttributes(): any {
  yield takeLatest(atypes.API_AUTH_SUCCESS, syncUserChatroomAttributes);
}

function* updateLastRead({ payload }: Action) {
  const orgId = yield select(getLastOrg);
  const { count } = payload;
  if (count !== 0) {
    try {
      yield call(chatroom.updateLastRead, payload.roomId, payload.uid, {
        count,
        orgId,
        lastRead: new Date()
      });

      yield put({
        type: atypes.UPDATE_LAST_READ_SUCCESS,
        payload: {}
      });
    } catch (error) {
      yield put({
        type: atypes.UPDATE_LAST_READ_FAILURE,
        payload: { roomId: payload.roomId, error }
      });
    }
  }
}

function* watchUpdateLastRead(): any {
  yield takeEvery(atypes.UPDATE_LAST_READ_REQUEST, updateLastRead);
}

function* updateFavourite({ payload }: Action) {
  try {
    yield call(chatroom.updateFavourite, payload);

    if (payload.isFavourite) {
      yield put({
        type: atypes.ADD_TO_FAVOURITE,
        payload: payload.roomId
      });
    } else {
      yield put({
        type: atypes.REMOVE_FROM_FAVOURITE,
        payload: payload.roomId
      });
    }
  } catch (err) {
    toast.error("Failed to favorite conversation.");
    yield put({
      type: atypes.UPDATE_FAVOURITE_FAILURE,
      payload: err
    });
  }
}

function* watchUpdateFavourite(): any {
  yield takeEvery(atypes.UPDATE_FAVOURITE_REQUEST, updateFavourite);
}

function* setChatroomAttribute({ payload }: Action): any {
  const oldAttributes = yield select(getChatroom(payload.roomId));
  const oldValues = R.mergeAll(
    R.map(x => ({ [x]: oldAttributes[x] }), R.keys(payload.value))
  );

  if (
    !(
      payload.value &&
      payload.value.title?.length >= 0 &&
      oldAttributes.title === payload.value.title
    )
  ) {
    yield put({
      type: atypes.CHANGE_CHATROOM_ATTRIBUTE,
      payload
    });

    // Update process table data
    const autoNo = oldAttributes?.autoNo;

    if (autoNo && !payload.columnId) {
      yield put({
        type: atypes.UPDATE_CHATROOM_FROM_CONVERSATION_MODAL,
        payload: {
          autoNo,
          values: {
            ...oldAttributes,
            ...payload.value,
            chatroomId: payload.roomId
          }
        }
      });
    }

    try {
      yield call(chatroom.updateAttribute, payload);
      yield put({
        type: atypes.SET_CHATROOM_ATTRIBUTE_SUCCESS,
        payload: {
          roomId: payload.roomId
        }
      });
    } catch (e) {
      console.error(e);
      yield put({
        type: atypes.SET_CHATROOM_ATTRIBUTE_FAILURE,
        payload: {
          roomId: payload.roomId,
          value: oldValues,
          failure: true
        }
      });

      const value = R.head(R.keys(payload.value) || []);

      if (value) {
        toast.error(
          `Error in Changing ${value.replace(/^\w/, c =>
            c.toUpperCase()
          )} of conversation`
        );
      }
    }
  }
}

function* watchSetChatroomAttribute(): any {
  yield takeEvery(atypes.SET_CHATROOM_ATTRIBUTE_REQUEST, setChatroomAttribute);
}

function* createChatroom() {
  try {
    const currentUser = yield select(getUser);
    const rejectedKeys = [
      "status",
      "preventRedirect",
      "status",
      "address",
      "settings",
      "error",
      "draft",
      "invocationCount",
      "loading",
      "templateTitle",
      "activeCount",
      "updatedAt",
      "updatedBy",
      "redirectChatroom",
      "analytics"
    ];

    // Filter out un-necessary things from process creation request
    const {
      conversationDialog,
      users: { byId: usersById }
    } = yield select(getAppState);

    const request = R.omit(
      conversationDialog.type === "workflow"
        ? rejectedKeys
        : [...rejectedKeys, "templateId"],
      conversationDialog
    );

    const { uid } = currentUser;

    // Log chatroom creation payload and surrounding state to Sentry
    Sentry.addBreadcrumb({
      category: "message",
      message: "Creating chatroom",
      level: "info",
      data: {
        ...request,
        title: request.title ?? null,
        creator: uid,
        parent: request.parent ? parseInt(request.parent, 10) : null
      }
    });

    const room = yield call(chatroom.create, {
      ...request,
      title: request.title ? request.title : null,
      creator: uid,
      parent: request.parent ? parseInt(request.parent, 10) : null
    });

    Sentry.captureMessage("Chatroom created successfully", {
      level: "info",
      extra: {
        roomId: room.id
      }
    });

    if (
      request.owner &&
      typeof request.owner === "object" &&
      request.owner.email &&
      room.owner &&
      !usersById[room.owner]
    ) {
      const { email } = request.owner;
      const displayName = R.head(R.split("@", email || ""));

      yield put({
        type: atypes.SEND_USER_INVITE_SUCCESS,
        payload: {
          user: {
            uid: R.type(room.owner) === "Object" ? room.owner.uid : room.owner,
            displayName: displayName ? capitalize(displayName || "") : null,
            email,
            pending: true
          }
        }
      });
    }

    // Resolving newly invited members
    if (room.members.length > 0) {
      yield put({
        type: atypes.SYNC_USERS_SUCCESS,
        payload: {
          users: R.mergeAll(R.map(user => ({ [user.uid]: user }), room.members))
        }
      });
    }

    yield put({
      type: atypes.CREATE_CHATROOM_SUCCESS,
      payload: {
        ...room,
        owner: R.type(room.owner) === "Object" ? room.owner.uid : room.owner,
        members: room.members.map(user => user.uid),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
    });

    if (!conversationDialog.preventRedirect) {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: room.address
        }
      });
    } else {
      yield put({
        type: atypes.REFETCH_WORKFLOW_INSTANCES,
        payload: {}
      });
    }
  } catch (e) {
    yield put({
      type: atypes.CREATE_CHATROOM_FAILURE,
      payload: { e }
    });
  }
}

function* watchCreateChatroom(): any {
  yield takeLatest(atypes.CREATE_CHATROOM_REQUEST, createChatroom);
}

function* updateCurrentChatroom(): any {
  while (true) {
    try {
      const actions = yield all([
        take(atypes.LOAD_CHATROOMS_SUCCESS),
        take(atypes.UPDATE_CURRENT_CHATROOM)
      ]);

      const chatrooms = (
        R.find(R.propEq("type", atypes.LOAD_CHATROOMS_SUCCESS))(actions) || {}
      ).payload;
      const { id } = (
        R.find(R.propEq("type", atypes.UPDATE_CURRENT_CHATROOM))(actions) || {}
      ).payload;

      let currentChatroom = null;

      R.map(room => {
        if (room.address === id) {
          currentChatroom = room.id;
        }
        return null;
      }, chatrooms);

      if (currentChatroom) {
        yield put({
          type: atypes.SET_CURRENT_CHATROOM_SUCCESS,
          payload: { id: currentChatroom }
        });
      }
    } catch (err) {
      yield put({
        type: atypes.SET_CURRENT_CHATROOM_FAILURE,
        payload: err
      });
    }
  }
}

function* searchChatroom({ payload }): any {
  try {
    const app = yield select(getAppState);
    let chatrooms = [];
    let currentUser = "";

    const { workflowTitle } = (yield select(getAppState)).chatRooms;

    switch ((payload.settings || {}).searchType) {
      case "uniqueConversation": {
        const uniqueValues = getUniqueValues(
          yield select(getAppState),
          payload.settings?.column
        );
        const allRooms = (yield select(getAppState)).chatRooms.byId;
        chatrooms = R.map(c => allRooms[`${c || ""}`], uniqueValues);
        break;
      }
      case "relatedConversations": {
        let chatroomIds = [];
        yield put({
          type: atypes.SEARCH_RELATED_CONVERSATIONS_SUCCESS,
          payload: []
        });
        const allRooms = app.chatRooms.byId;
        const relatedConversations =
          app.chatRooms.attributes.relatedConversations[
            `${app.chatRooms.current}`
          ];
        currentUser = app.currentUser.uid;
        if (payload.settings.relation) {
          const { fosterParents, parent } = relatedConversations;
          chatroomIds = [...(fosterParents || [])];
          if (parent) chatroomIds.push(parent);
        } else {
          const { children, fosterChildren } = relatedConversations;
          chatroomIds = [...(children || []), ...(fosterChildren || [])];
        }
        chatrooms = chatroomIds.map(room => allRooms[room]);
        break;
      }
      default:
        chatrooms = getChatrooms(yield select(getAppState));
    }

    const { settings, searchString } = payload;

    // Filter out the irrelevant chatrooms
    const filterRooms = R.filter(
      R.allPass([
        notCanceled,
        containsTitle(searchString, workflowTitle),
        containsType(settings.type),
        containsWorkflow(settings.workflow),
        containsOverdue(settings.category === 1),
        containsCompleted(settings.category === 2),
        containsPending(settings.category === 3),
        containsOwner(settings.category === 4, currentUser),
        // Checks if the chatroom has been archived (either manually or through autoArchive)
        // settings.showArchived decides whether or not to filter out the archived conversations
        containsArchived(!settings.showArchived, app.templateStatus)
      ])
    );

    const sortFunction = payload.settings.reverse
      ? reverseSortById
      : sortByAutoNumber;

    let result = R.pipe(filterRooms, sortFunction)(chatrooms);

    result = R.pipe(R.pluck("id"), R.uniq)(result);

    switch ((payload.settings || {}).searchType) {
      case "uniqueConversation":
        yield put({
          type: atypes.SEARCH_UNIQUE_CONVERSATION_SUCCESS,
          payload: { column: payload?.settings?.column, result }
        });
        break;
      case "relatedConversations": {
        yield put({
          type: atypes.SEARCH_RELATED_CONVERSATIONS_SUCCESS,
          payload: result
        });
        break;
      }
      default:
        yield put({
          type: atypes.SEARCH_CHATROOM_SUCCESS,
          payload: { result }
        });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.SEARCH_CHATROOM_FAILURE,
      payload: { e: e.message }
    });
  }
}

function* watchSearchChatroom(): any {
  yield throttle(100, atypes.SEARCH_CHATROOM_REQUEST, searchChatroom);
}

function* resetChatroomSearch({ payload }: Action): any {
  yield put({
    type: atypes.SEARCH_CHATROOM_REQUEST,
    payload: {
      searchString: "",
      settings: payload.settings
    }
  });
}

function* watchResetChatroomSearch(): any {
  yield takeEvery(atypes.RESET_CHATROOM_SEARCH, resetChatroomSearch);
}

function* cancelConversation({ payload }: Action): any {
  try {
    const { roomId, comment } = payload.value;
    if (R.isEmpty(comment)) {
      yield call(chatroom.cancelConversation, { roomId, value: {} });
    } else {
      yield call(chatroom.cancelConversation, payload);
    }

    yield put({
      type: atypes.CANCEL_CHATROOM_SUCCESS,
      payload
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.CANCEL_CHATROOM_FAILURE,
      payload
    });
  }
}

function* watchCancelConversation(): any {
  yield takeEvery(atypes.CANCEL_CHATROOM_REQUEST, cancelConversation);
}

function* handleRemoveRoom({ payload }: Action): any {
  // Check if current chatroom is removed from state
  const currentRoomId = yield select(getCurrentChatroom);
  if (payload.roomId === currentRoomId) {
    // TODO: Find better way to handle this condition
    window.location.reload();
  }
}

function* watchRemoveRoom(): any {
  yield takeEvery(atypes.REMOVE_CHATROOM, handleRemoveRoom);
}

function* changeConversationType({ payload }: Action): any {
  try {
    const { roomId, type, templateId } = payload;

    if (type === "workflow") {
      yield call(chatroom.changeConversationType, {
        roomId,
        newType: {
          type,
          templateId
        }
      });
    } else {
      yield call(chatroom.changeConversationType, {
        roomId,
        newType: {
          type
        }
      });
    }

    yield put({
      type: atypes.CHANGE_CONVERSATION_TYPE_SUCCESS,
      payload
    });

    toast.success("Successfully changed the type of the conversation");
  } catch (error) {
    toast.error(`Error changing type of conversation`);
    yield put({
      type: atypes.CHANGE_CONVERSATION_TYPE_FAILURE,
      payload: { error }
    });
  }
}

function* watchChangeConversationType(): any {
  yield takeEvery(
    atypes.CHANGE_CONVERSATION_TYPE_REQUEST,
    changeConversationType
  );
}

let fetchedConversations = [];

function* fetchChatroomDetails({ payload }: Action): any {
  if (!R.includes(payload.id, fetchedConversations)) {
    try {
      if (payload.id) {
        fetchedConversations.push(payload.id);
        const chatroomDocument = yield call(chatroom.getChatroom, payload.id);

        let { status, active, canceled, groups, members } = chatroomDocument;

        if (status === undefined || typeof status === "object") {
          if (canceled) {
            status = -3;
          } else if (active === false) {
            status = -2;
          } else {
            status = -1;
          }
        }

        yield put({
          type: atypes.FETCH_CHATROOM_DETAILS_SUCCESS,
          payload: {
            [payload.id]: {
              ...R.pickAll(chatroomMetaData, chatroomDocument),
              id: `${payload.id}`,
              status
            }
          }
        });

        const currentRoom = yield select(getCurrentChatroom);
        const conversationModalId =
          getConversationModalId(yield select(getAppState)) || "";
        if (
          `${currentRoom}` === `${payload.id}` ||
          `${conversationModalId ?? ""}` === `${payload.id}`
        ) {
          yield put({
            type: atypes.SET_ROOM_PARTICIPANTS,
            payload: {
              groups,
              members
            }
          });
        }
      }
    } catch (error) {
      fetchedConversations = R.reject(
        R.equals(payload.id),
        fetchedConversations
      );

      yield put({
        type: atypes.FETCH_CHATROOM_DETAILS_FAILURE,
        payload: { error }
      });
    }
  }
}

function* watchFetchChatroomDetails(): any {
  yield takeEvery(atypes.FETCH_CHATROOM_DETAILS_REQUEST, fetchChatroomDetails);
}

function* createDirectMessage({ payload }: Action): any {
  try {
    const app = yield select(getAppState);
    const { directMessages } = app.chatRooms;
    const currentUser = app.currentUser.uid;

    const uid = payload.member;

    if (uid === currentUser) {
      toast.error("You can't chat with your self");
    } else if (directMessages[uid]) {
      yield delay(400);

      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: `${directMessages[uid]}`
        }
      });

      yield put({
        type: atypes.CLEAR_NEW_CONVERSATION,
        payload: {}
      });
    } else {
      const room = yield call(chatroom.create, {
        members: [payload.member, currentUser],
        title: null,
        creator: currentUser,
        type: "direct"
      });
      yield put({
        type: atypes.LOAD_DIRECT_CONVERSATIONS,
        payload: {
          [payload.member]: room.address
        }
      });

      yield put({
        type: atypes.CREATE_CHATROOM_SUCCESS,
        payload: {
          ...room,
          owner: room.owner ? room.owner.uid : null,
          members: room.members.map(user => user.uid),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      });

      yield put({
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: room.address
        }
      });
    }
  } catch (error) {
    toast.error("Unable create direct conversation");
    yield put({
      type: atypes.CREATE_DIRECT_CONVERSATION_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchCreateDirectMessage(): any {
  yield takeEvery(
    atypes.CREATE_DIRECT_CONVERSATION_REQUEST,
    createDirectMessage
  );
}

function* getChildConversations({ payload }: Action): any {
  try {
    const nestedRows = yield call(
      chatroom.getChildConversations,
      payload.roomId
    );
    yield put({
      type: atypes.FETCH_NESTED_ROWS_SUCCESS,
      payload: {
        parentId: payload.roomId,
        nestedRows: nestedRows.map(room => ({
          processTitle: room.processTitle,
          chatroomId: room.id,
          title: room.title,
          childCount: room.childCount,
          priority: room.priority,
          status: room.status,
          dueDate: room.dueDate,
          parentId: room.parent,
          parentTitle: room.parent,
          createdAt: room.createdAt,
          updatedAt: room.updatedAt,
          completedAt: room.completedAt,
          creator: room.creator,
          age: room.age,
          seqNo: room.seqNo,
          autoNo: room.autoNo,
          versionCount: room.versionCount,
          currentVersion: room.currentVersion,
          version: room.version,
          versionComment: room.versionComment,
          owner: room.owner,
          members: room.members || [],
          memberCount: room.memberCount,
          description: room.description,
          active: room.active,
          canceled: room.canceled,
          privacy: room.privacy,
          templateId: room.templateId,
          archived: room.archived
        }))
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_CHILD_CONVERSATIONS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetChildConversations(): any {
  yield takeEvery(
    atypes.GET_CHILD_CONVERSATIONS_REQUEST,
    getChildConversations
  );
}

export function* newWorkflow({ payload }: Action): any {
  try {
    const byId = yield select(getWorkflowsById);
    const currentUser = yield select(getCurrentUserId);

    const workflow = byId[`${payload.id}`];

    if (workflow) {
      let request = {
        templateId: parseInt(payload.id, 10),
        ...workflow,
        templateTitle: workflow.title,
        title: "",
        description: "",
        type: "workflow"
      };

      const removeCreator = userUid => userUid !== currentUser;

      const creatorIsOwner = request?.settings?.creatorIsOwner;
      const creatorIsParticipant = request?.settings?.creatorIsParticipant;

      if (creatorIsOwner === true || creatorIsOwner === undefined) {
        if (!request.owner) {
          request.owner = currentUser;
        }
      } else if (creatorIsOwner === false) {
        request.owner = null;
      }

      if (creatorIsParticipant === true || creatorIsParticipant === undefined) {
        if (request.owner) {
          request.members = R.uniq([
            request.owner,
            currentUser,
            ...(request.members || [])
          ]);
        } else {
          request.members = R.uniq([currentUser, ...(request.members || [])]);
        }
        // Remove creator from participant
      } else if (creatorIsParticipant === false) {
        if (request.owner) {
          const uniqMembers = R.uniq([
            request.owner,
            ...(request.members || [])
          ]);
          request.members = R.filter(removeCreator, uniqMembers);
        } else {
          const uniqMembers = R.uniq([...(request.members || [])]);
          request.members = R.filter(removeCreator, uniqMembers);
        }
      }

      yield put({
        type: atypes.SET_NEW_CONVERSATION_ATTRIBUTES,
        payload: {
          value: request
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchNewWorkflow(): any {
  yield takeEvery(atypes.SET_NEW_WORKFLOW, newWorkflow);
}

function* setMessageAsConversationTitle({ payload }: Action): any {
  try {
    const app = yield select(getAppState);
    const usersById = app.users.byId;
    const text = getMessageText(app, payload.id);

    // eslint-disable-next-line no-useless-escape
    const mentions = R.match(/\<@(.*?)\>/g, text) || [];

    if (mentions.length > 0) {
      // $FlowFixMe
      const conversationTitle = R.reduce(
        (acc: string, user: string) => {
          const uid = (user || "").replace("<@", "").replace(">", "");
          const mentionedUser = usersById[uid] || {};
          const name = mentionedUser.displayName || mentionedUser.email || "";
          return (acc || "").replace(user || "", `@${name}`);
        },
        text,
        mentions
      );

      yield put({
        type: atypes.SET_NEW_CONVERSATION_ATTRIBUTES,
        payload: {
          value: {
            message: payload.id,
            title: conversationTitle
          }
        }
      });
    } else {
      yield put({
        type: atypes.SET_NEW_CONVERSATION_ATTRIBUTES,
        payload: {
          value: {
            message: payload.id,
            title: text
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchSetMessageAsConversationTitle(): any {
  yield takeEvery(
    atypes.SET_MESSAGE_AS_CONVERSATION_TITLE,
    setMessageAsConversationTitle
  );
}

function* startTypingSync({ payload }: Action) {
  try {
    const typingTask = yield fork(
      rsf.firestore.syncCollection,
      connection().collection(`chatrooms/${payload.id}/typing`),
      {
        successActionCreator: (snapshot: Object) =>
          loadTyping(snapshot, payload.id)
      }
    );
    while (true) {
      const stopAction = yield take(atypes.STOP_TYPING_SYNC);
      const { id } = stopAction.payload;
      if (id === payload.id && typingTask) {
        yield cancel(typingTask);
        break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchStartTypingSync(): any {
  yield takeEvery(atypes.SET_CURRENT_CHATROOM_SUCCESS, startTypingSync);
}

function* loadRoomTyping({ payload }: Action) {
  try {
    const typing = {};
    for (const doc of payload.snapshot.docs) {
      const { lastTyped } = doc.data();
      typing[doc.id] = {
        uid: doc.id,
        lastTyped: lastTyped ? lastTyped.toDate() : null
      };
    }

    yield put({
      type: atypes.SYNC_TYPING_SUCCESS,
      payload: {
        roomId: payload.roomId,
        typing
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SYNC_TYPING_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchLoadRoomTyping(): any {
  yield takeEvery(atypes.LOAD_TYPING, loadRoomTyping);
}

function* setTyping({ payload }: Action) {
  try {
    const currentUser = yield select(getCurrentUserId);

    yield call(chatroom.setUserTyping, payload.roomId, currentUser);
  } catch (error) {
    yield put({
      type: atypes.SET_TYPING_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSetTyping(): any {
  yield takeEvery(atypes.SET_TYPING_REQUEST, setTyping);
}

function* deleteTyping({ payload }: Action) {
  try {
    const currentUser = yield select(getCurrentUserId);

    yield call(chatroom.deleteUserTyping, payload.roomId, currentUser);

    yield put({
      type: atypes.DELETE_TYPING_SUCCESS,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.DELETE_TYPING_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchDeleteTyping(): any {
  yield takeEvery(atypes.DELETE_TYPING_REQUEST, deleteTyping);
}

function* inviteOwner({ payload }: Action): any {
  try {
    const user = yield call(chatroom.updateAttribute, payload);
    yield put({
      type: atypes.INVITE_OWNER_SUCCESS,
      payload: user[0]?.owner
    });

    yield put({
      type: atypes.CHANGE_CHATROOM_ATTRIBUTE,
      payload: {
        roomId: payload.roomId,
        value: { owner: user[0]?.owner?.uid }
      }
    });
  } catch (error) {
    toast.error("Unable to invite user");
    yield put({
      type: atypes.INVITE_OWNER_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchInviteOwner(): any {
  yield takeEvery(atypes.INVITE_OWNER_REQUEST, inviteOwner);
}

function* fetchAllRoomsAfterInvervals(): any {
  try {
    while (true) {
      // Wait for 1 hour
      yield delay(1000 * 60 * 60);
      let counter = parseInt(localStorage.getItem("counter") || "0", 10);
      counter += 1;
      localStorage.setItem("counter", `${counter || ""}`);

      if (counter === 11) {
        localStorage.setItem("counter", "0");
        yield put({
          type: atypes.REFETCH_ALL_CONVERSATIONS_REQUEST,
          payload: {}
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchFetchAllRoomsAfterInvervals(): any {
  yield takeLatest(atypes.API_AUTH_SUCCESS, fetchAllRoomsAfterInvervals);
}

function* updateCurrentView({ meta }): any {
  try {
    const app = yield select(getAppState);

    const currentFilterName = app.chatRooms.filters.current.name;
    const view = app.view;

    if (view !== currentFilterName) {
      // Show the participant loader when coming to My Conversations page
      const roomAddress = meta.location.current?.payload?.id;
      yield put({
        type: atypes.SHOW_PARTICIPANT_LOADER,
        payload: {
          address: roomAddress
        }
      });
      yield put(showView(currentFilterName));
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchSetCurrentChatroomRequest(): any {
  yield takeLatest(atypes.SET_CURRENT_CHATROOM_REQUEST, updateCurrentView);
}

function* updateChatroomTemplate({ payload }): any {
  try {
    const roomId = `${payload.id}`;
    const { templateId } = yield select(getChatroom(roomId));
    yield put({
      type: atypes.UPDATE_CHATROOM_TEMPLATE,
      payload: {
        templateId
      }
    });
  } catch (err) {
    console.error(err);
  }
}

function* watchUpdateChatroomTemplate(): any {
  yield takeLatest(atypes.OPEN_CONVERSATION_MODAL, updateChatroomTemplate);
}

export default [
  watchFetchAllRoomsAfterInvervals(),
  watchInviteOwner(),
  watchSetMessageAsConversationTitle(),
  watchNewWorkflow(),
  watchGetChildConversations(),
  watchFetchChatroomDetails(),
  watchSyncUserChatroomAttributes(),
  watchUpdateLastRead(),
  watchUpdateFavourite(),
  watchSetChatroomAttribute(),
  watchCreateChatroom(),
  watchSearchChatroom(),
  watchResetChatroomSearch(),
  watchCancelConversation(),
  watchRemoveRoom(),
  watchChangeConversationType(),
  updateCurrentChatroom(),
  watchCreateDirectMessage(),
  watchStartTypingSync(),
  watchLoadRoomTyping(),
  watchSetTyping(),
  watchDeleteTyping(),
  watchSetCurrentChatroomRequest(),
  watchUpdateChatroomTemplate()
];
