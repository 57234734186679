// @flow

import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type { ConversationDialog, Action } from "src/types";

const initialState = {
  title: "",
  type: "group",
  owner: null,
  members: [],
  groups: [],
  error: null,
  loading: null,
  id: ""
};

const conversationDialog = (
  state: ConversationDialog = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_NEW_CONVERSATION_ATTRIBUTE:
      return { ...state, [payload.id]: payload.value };
    case atypes.CREATE_CHATROOM_FAILURE:
      return { ...state, error: "Error Creating Conversation" };
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return { ...state, loading: false, error: null };
    case atypes.SET_NEW_CONVERSATION_ATTRIBUTES:
      return { ...state, ...payload.value };
    case atypes.CLEAR_NEW_CONVERSATION:
      return { ...initialState, loading: false, error: null };
    case atypes.CREATE_CHATROOM_REQUEST:
      return { ...state, loading: true, error: null };
    case atypes.CREATE_CHATROOM_SUCCESS:
      return { ...state, loading: false, error: null };
    case atypes.CREATE_DIRECT_CONVERSATION_REQUEST:
      return { ...state, error: null, loading: true };
    default:
      return state;
  }
};

export default conversationDialog;

export const getConversationDialog = (state: ConversationDialog) => {
  const rejectedKeys = [
    "settings",
    "error",
    "draft",
    "invocationCount",
    "loading",
    "activeCount",
    "updatedAt",
    "updatedBy",
    "redirectChatroom"
  ];
  return R.omit(rejectedKeys, state);
};
