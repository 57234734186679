// @flow

import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { HStack, Text, Box } from "@chakra-ui/react";
import Icon from "src/icons";
import { active } from "src/styles/constants/colors";
import useBoolean from "src/hooks/useBoolean";
import Dropdown from "src/components/Dropdown";
import { getCurrentChecklist } from "src/reducers";
import { downloadJson } from "src/api/chatroom";
import * as styles from "./styles";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  setIsMoreMenuOpen: (value: boolean) => void
};

const DownloadJSON = ({ roomId, setIsMoreMenuOpen }: Props) => {
  const dropdownRef = useRef(null);
  const { value: isOpen, toggleBoolean: toggleDropdown } = useBoolean();

  const checklists = useSelector(({ app }) => getCurrentChecklist(app));
  return (
    <>
      {checklists[0] && (
        <>
          <HStack
            ref={dropdownRef}
            w="full"
            sx={styles.MenuItem}
            onClick={e => {
              e.stopPropagation();
              if (dropdownRef.current) {
                dropdownRef.current.blur();
              }
              toggleDropdown();
            }}
            tabIndex={0}
            onKeyPress={e => {
              e.stopPropagation();
              if (e.key === "Enter") {
                toggleDropdown();
              }
            }}
          >
            <Icon type="download2" fill={active} />
            <Text as="span">Download JSON</Text>
            {isOpen ? (
              <Box sx={styles.ToggleIconContainer}>
                <Icon type="caretUp" />
              </Box>
            ) : (
              <Box sx={styles.ToggleIconContainer}>
                <Icon type="caretDown" />
              </Box>
            )}
          </HStack>
          {isOpen && (
            <Dropdown
              isOpen={isOpen}
              customStyles={{
                DropdownWrapper: { width: "100%", marginBottom: 0 },
                DropdownMenuWrapper: styles.DropdownMenuWrapper
              }}
            >
              {() => {
                return (
                  <div>
                    <Text
                      sx={styles.DropdownItem}
                      as="span"
                      onClick={() => {
                        downloadJson("adobe", roomId, checklists[0].id);
                        setIsMoreMenuOpen(false);
                      }}
                      tabIndex={0}
                      onKeyPress={e => {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                          downloadJson("adobe", roomId, checklists[0].id);
                        }
                        setIsMoreMenuOpen(false);
                      }}
                    >
                      JSON for Adobe
                    </Text>
                    <Text
                      sx={styles.DropdownItem}
                      as="span"
                      onClick={() => {
                        downloadJson("pdfgen", roomId, checklists[0].id);
                        setIsMoreMenuOpen(false);
                      }}
                      tabIndex={0}
                      onKeyPress={e => {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                          downloadJson("pdfgen", roomId, checklists[0].id);
                        }
                        setIsMoreMenuOpen(false);
                      }}
                    >
                      JSON for PDFGeneratorAPI
                    </Text>
                    <Text
                      sx={styles.DropdownItem}
                      as="span"
                      onClick={() => {
                        downloadJson("pdfgenv2", roomId, checklists[0].id);
                        setIsMoreMenuOpen(false);
                      }}
                      tabIndex={0}
                      onKeyPress={e => {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                          downloadJson("pdfgenv2", roomId, checklists[0].id);
                        }
                        setIsMoreMenuOpen(false);
                      }}
                    >
                      JSON for PDFGeneratorAPI v2
                    </Text>
                  </div>
                );
              }}
            </Dropdown>
          )}
        </>
      )}
    </>
  );
};

export default DownloadJSON;
