// @flow

import React from "react";

import {} from "./styles";

type Props = {
  width?: number | string,
  height?: number | string,
  handleClick?: Function,
  fill?: string
};

const Decrement = ({
  handleClick = () => {},
  width = 24,
  height = 24,
  fill = "#fa5e5b",
  ...restProps
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    style={{ cursor: "pointer" }}
    {...restProps}
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z"
      fill={fill}
    />
  </svg>
);

export default Decrement;
