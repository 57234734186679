// @flow

import React from "react";
import { connect } from "react-redux";
import { Portal } from "@chakra-ui/react";
import Link from "redux-first-router-link";

import {
  StatusRemove as StyledStatusRemove,
  Body,
  Footer,
  Overlay
} from "./styles";
import { ButtonLink } from "src/styles/buttons";
import Name from "src/components/Status/Name";
import { hideStatusRemoveWarning } from "src/actions/workflows";
import * as atypes from "src/constants/actionTypes";

import type { AppState } from "src/types";

type Props = {
  show: boolean,
  id: number,
  count: number,
  workflow: number,
  _hideStatusRemoveWarning: Function
};

const StatusRemove = ({
  show,
  id,
  count,
  workflow,
  _hideStatusRemoveWarning
}: Props) => {
  if (!show) {
    return <></>;
  }

  return (
    <Portal>
      <Overlay />
      <StyledStatusRemove>
        <h4>
          Cannot remove status “<Name id={id} />”
        </h4>
        <Body>
          There are open conversations with that status. You need to change the
          status on all those conversations to a different status before we can
          remove “<Name id={id} />”
        </Body>
        <Footer>
          <Link
            onClick={_hideStatusRemoveWarning}
            to={{
              type: atypes.SET_PROCESS_REQUEST,
              meta: {
                query: {
                  id: workflow,
                  sort: ["seqNo:asc"],
                  status: [id]
                }
              }
            }}
          >
            View {count} conversations marked as “<Name id={id} />”
          </Link>
          <ButtonLink type="button" onClick={_hideStatusRemoveWarning}>
            Dismiss
          </ButtonLink>
        </Footer>
      </StyledStatusRemove>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  ...app.workflow.statusRemoveWarning
});

export default connect(mapStateToProps, {
  _hideStatusRemoveWarning: hideStatusRemoveWarning
})(StatusRemove);
