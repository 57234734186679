// @flow

import { connect } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { Portal } from "@chakra-ui/react";

import Navigation from "../../Navigation";
import { OpaqueOverlay as StyledTemplates } from "../styles";
import Table from "./Table";
import Header from "./TemplateHeader";
import { getWorkflowTemplates, getCurrentUserId } from "src/reducers";
import {
  deleteWorkflow,
  setWorkflowBuiderAttributes,
  setEditProcess,
  setCreateProcess
} from "src/actions/workflows";
import type { AppState, Workflow, UID } from "src/types";
import { dataStages } from "../../../../constants";

type Props = {
  workflows: Array<Workflow>,
  deleteWorkflow: Function,
  setWorkflowBuiderAttributes: Function,
  setEditProcess: Function,
  setCreateProcess: Function,
  uid: UID
};

const Templates = ({
  setWorkflowBuiderAttributes,
  deleteWorkflow,
  workflows,
  setEditProcess,
  setCreateProcess,
  uid
}: Props) => {
  const [text, setText] = useState("");
  const [sort, setSort] = useState("title");
  const [ascending, setAscending] = useState(true);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    setMatches(
      workflows.filter(w =>
        R.includes(R.toLower(text), R.toLower(w.title || ""))
      )
    );
  }, [workflows, setMatches]);

  const handleSearch = useCallback(
    (value: string) => {
      setText(value);
      setMatches(
        workflows.filter(w =>
          R.includes(R.toLower(value), R.toLower(w.title || ""))
        )
      );
    },
    [setText, setMatches, workflows]
  );

  const sortBy = useCallback(
    (column: string) => {
      if (column === sort) {
        setSort(column);
        setAscending(!ascending);
      } else {
        setSort(column);
      }
    },
    [sort, ascending, setSort, setAscending]
  );

  const handleDelete = useCallback(
    (workflow: Workflow) => {
      deleteWorkflow(workflow.id);
    },
    [deleteWorkflow]
  );

  const handleEdit = useCallback(
    (workflow: Workflow, e: ?any) => {
      if (e) {
        e.preventDefault();
      }
      setWorkflowBuiderAttributes({
        show: true,
        loading: dataStages.fetching
      });
      setEditProcess(workflow.id);
    },
    [setWorkflowBuiderAttributes, setEditProcess]
  );

  const handleCreate = useCallback(() => {
    setWorkflowBuiderAttributes({
      members: [uid],
      owner: uid,
      draft: false,
      show: true
    });
    setCreateProcess(); // Navigate to /manage/new
  }, [uid, setWorkflowBuiderAttributes, setCreateProcess]);

  return (
    <Portal>
      <StyledTemplates>
        <Navigation tab="workflow" />

        <Header
          text={text}
          handleSearch={handleSearch}
          handleCreate={handleCreate}
        />

        <Table
          sort={sort}
          sortBy={sortBy}
          matches={matches}
          ascending={ascending}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </StyledTemplates>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  workflows: getWorkflowTemplates(app),
  lastCreated: app.workflow.lastCreated,
  uid: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  deleteWorkflow,
  setWorkflowBuiderAttributes,
  setEditProcess,
  setCreateProcess
})(Templates);
