// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Portal } from "@chakra-ui/react";
import * as R from "ramda";

import Table from "./Table";
import {
  Info as StyledInfo,
  Label,
  ButtonOutline,
  ButtonGroup,
  UserInfoModal
} from "./styles";
import * as colors from "src/styles/constants/colors";
import Picture from "src/components/user/picture/Large";
import Name from "src/components/user/Edit/Name";
import Close from "src/components/Close";
import PhoneNumber from "src/components/user/Edit/PhoneNumber";
import Department from "src/components/department/Edit";
import Email from "src/components/user/Edit/Email";
import UserName from "src/containers/user/NameOrEmail";
import {
  getUserRole,
  isUserDisabled,
  getRole,
  getCurrentUserId
} from "src/reducers";
import {
  getUserGroups,
  getUserProcess,
  getOnboardingStatus,
  showDisableUserModal,
  enableUser
} from "src/actions/user";
import { Overlay } from "src/styles/modal";
import { createDirectConversation } from "src/actions/chatroom";

import type { AppState, UID } from "src/types";

type Props = {
  uid: UID,
  role: string,
  disabled: boolean,
  userRole: string,
  currentUser: UID,
  handleClose: Function,
  _getUserGroups: Function,
  _getUserProcess: Function,
  _getOnboardingStatus: Function,
  _createDirectConversation: Function,
  _showDisableUserModal: Function,
  _enableUser: Function
};

const Info = ({
  uid,
  role,
  disabled,
  handleClose,
  userRole,
  currentUser,
  _createDirectConversation,
  _showDisableUserModal,
  _enableUser,
  _getUserGroups,
  _getUserProcess,
  _getOnboardingStatus
}: Props) => {
  useEffect(() => {
    _getUserGroups(uid);
    _getUserProcess(uid);
    _getOnboardingStatus(uid);
  }, [_getUserGroups, _getUserProcess, _getOnboardingStatus, uid]);

  const isAdminOrMember = R.includes(userRole, ["admin", "member"]);

  // Show change role button only if current logged user role is admin
  // or phantom & user is not disabled & his role is admin or member
  const showChangeRoleButton =
    uid !== currentUser &&
    (role === "admin" || role === "phantom") &&
    !disabled &&
    isAdminOrMember;

  return (
    <Portal>
      <Overlay />
      <UserInfoModal>
        <StyledInfo>
          <Picture uid={uid} />
          <div>
            <Close handleClose={handleClose} />
            <Name uid={uid} />
            <Label>Email ID</Label>
            <Email uid={uid} />

            <Label>Phone Number</Label>
            <PhoneNumber uid={uid} />

            <Label>Department</Label>
            <Department uid={uid} />
            <ButtonGroup showChangeRoleButton={showChangeRoleButton}>
              <ButtonOutline
                type="button"
                onClick={() => _createDirectConversation(uid)}
              >
                <span>Message</span>
                <UserName uid={uid} />
              </ButtonOutline>
              {(role === "admin" || role === "phantom") &&
                !disabled &&
                uid !== currentUser && (
                  <ButtonOutline
                    type="button"
                    onClick={() =>
                      _showDisableUserModal({
                        uid,
                        adminModal: false,
                        memberModal: false
                      })
                    }
                    colors={colors.secondary}
                  >
                    <span>Disable</span>
                    <UserName uid={uid} />
                  </ButtonOutline>
                )}
              {(role === "admin" || role === "phantom") && disabled && (
                <ButtonOutline
                  type="button"
                  onClick={() => _enableUser(uid)}
                  colors={colors.active}
                >
                  <span>Enable</span>
                  <UserName uid={uid} />
                </ButtonOutline>
              )}

              {showChangeRoleButton && (
                <ButtonOutline
                  type="button"
                  onClick={() => {
                    userRole === "admin"
                      ? _showDisableUserModal({
                          uid,
                          adminModal: true,
                          memberModal: false
                        })
                      : userRole === "member"
                      ? _showDisableUserModal({
                          uid,
                          adminModal: false,
                          memberModal: true
                        })
                      : {};
                  }}
                  colors={colors.active}
                >
                  <span>
                    {userRole === "admin"
                      ? "Make member"
                      : userRole === "member"
                      ? "Make Admin"
                      : ""}
                  </span>
                </ButtonOutline>
              )}
            </ButtonGroup>
          </div>
        </StyledInfo>

        <Table uid={uid} />
      </UserInfoModal>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  role: getUserRole(app),
  userRole: getRole(app, props.uid),
  disabled: isUserDisabled(app, props.uid),
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  _getUserGroups: getUserGroups,
  _getUserProcess: getUserProcess,
  _getOnboardingStatus: getOnboardingStatus,
  _createDirectConversation: createDirectConversation,
  _showDisableUserModal: showDisableUserModal,
  _enableUser: enableUser
})(Info);
