// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Banner } from "src/components/Unifize";
import { Section, SectionTitle, SectionContent, Divider } from "./styles";
import Creator from "./CreatorItem";
import type { Group, UnifizeUser } from "src/types";
import {
  getUsersFromIds,
  getRoleTitles,
  getGroupDetails,
  getChecklistFieldDetails
} from "src/reducers";

import type {
  RevisionFieldSettingsv2,
  ApprovalFieldSettingsV2
} from "src/types";

type Props = {
  settings: RevisionFieldSettingsv2 | ApprovalFieldSettingsV2,
  path: string
};

const CreatorDetails = ({ settings, path }: Props) => {
  const { [path]: value = {} } = settings;
  const {
    groups = [],
    roles = [],
    users = [],
    fields = [],
    owner = false,
    allParticipants = false,
    preventRequester = false
  } = value;

  const selectedUsers: Array<UnifizeUser> =
    useSelector(({ app }) => getUsersFromIds(app, users)) || [];
  const roleTitles = useSelector(({ app }) => getRoleTitles(app)) || [];

  const selectedRoles = roles.flatMap(role =>
    roleTitles.filter(item => item.id === role)
  );

  const selectedGroups: Array<Group> =
    useSelector(({ app }) => getGroupDetails(app, groups)) || [];

  const selectedFieldsMap = useSelector(({ app }) =>
    fields.reduce((acc, id) => {
      const field = getChecklistFieldDetails(app, String(id));
      // $FlowFixMe
      acc[id] = field?.toJS() || {};
      return acc;
    }, {})
  );

  const selectedFields = fields.map(id => ({
    id,
    ...selectedFieldsMap[id]
  }));
  return (
    <>
      {preventRequester && (
        <Banner message="Requesters cannot add signatures." />
      )}

      {(owner || allParticipants) && (
        <Section>
          {owner && (
            <Creator icon="ownerV2" title="Owner" iconColor="#F8F9FC" />
          )}
          {allParticipants && (
            <Creator
              icon="participants"
              title="All Participants"
              iconColor="#F5FBFF"
            />
          )}
        </Section>
      )}

      {selectedGroups.length > 0 && (
        <>
          <Section>
            <SectionTitle>Groups</SectionTitle>
            <SectionContent>
              {selectedGroups.map(group =>
                group ? (
                  <Creator
                    key={group.id}
                    img={group.photoUrl}
                    title={group.title}
                  />
                ) : null
              )}
            </SectionContent>
          </Section>
          <Divider />
        </>
      )}

      {selectedRoles.length > 0 && (
        <>
          <Section>
            <SectionTitle>Roles</SectionTitle>
            <SectionContent>
              {selectedRoles.map(item =>
                item ? (
                  <Creator hideIcon key={item.id} title={item.label} />
                ) : null
              )}
            </SectionContent>
          </Section>
          <Divider />
        </>
      )}

      {selectedFields.length > 0 && (
        <>
          <Section>
            <SectionTitle>Fields</SectionTitle>
            <SectionContent>
              {selectedFields.map(item =>
                item ? (
                  <Creator hideIcon key={item.id} title={item.label} />
                ) : null
              )}
            </SectionContent>
          </Section>
          <Divider />
        </>
      )}

      {selectedUsers.length > 0 && (
        <Section>
          <SectionTitle>Specific users</SectionTitle>
          {selectedUsers.map(user =>
            user ? (
              <Creator
                key={user.uid}
                img={user.photoUrl}
                title={user.displayName || user.email}
              />
            ) : null
          )}
        </Section>
      )}
    </>
  );
};

export default CreatorDetails;
