// @flow

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Portal } from "@chakra-ui/react";

import { StyledReports } from "../../Reports/Templates/styles";
import Header from "src/components/SearchHeader.old";
import Table from "./Table";
import Navigation from "src/components/Manage/Navigation";
import { searchForm } from "src/actions/forms";
import FormBuilder from "src/components/Manage/Forms/Builder";

type Props = {
  formModal: boolean,
  openFormModal: Function,
  closeFormModal: Function,
  _searchForm: Function
};

const Reports = ({
  formModal,
  openFormModal,
  closeFormModal,
  _searchForm
}: Props) => {
  const [text, setText] = useState("");
  useEffect(() => {
    _searchForm("");
  }, []);

  const handleChange = (newValue: string) => {
    setText(newValue);
    _searchForm(newValue);
  };

  return (
    <Portal>
      <StyledReports>
        {formModal && <FormBuilder closeFormModal={closeFormModal} />}
        <Navigation tab="forms" />
        <Header
          text={text}
          handleChange={handleChange}
          handleCreate={() => openFormModal()}
          button="New Form Template"
          instant
        />
        <Table />
      </StyledReports>
    </Portal>
  );
};

export default connect(null, { _searchForm: searchForm })(Reports);
