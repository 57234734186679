// @flow

import React from "react";
import { useSelector } from "react-redux";

import { useDisclosure } from "@chakra-ui/react";

import { isAnyTemplateRowSelected } from "src/reducers";
import UnifizeButton from "src/components/Unifize/Button";
import InlineIcon from "src/icons";
import * as colors from "src/styles/constants/colors";

import ExportModal from "./ExportModal";

const JSONExport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const rowSelected: boolean = useSelector(({ app }) =>
    isAnyTemplateRowSelected(app)
  );

  if (rowSelected) {
    return (
      <>
        <UnifizeButton
          variant="default"
          handleClick={onOpen}
          isLoading={false}
          color={colors.lightBlue600}
          border="solid 1px"
          bgColor="white"
          borderRadius="4px"
          width="auto"
        >
          <InlineIcon type="uploadBlue" />
          Export
        </UnifizeButton>
        {isOpen && <ExportModal isOpen={isOpen} onClose={onClose} />}
      </>
    );
  }
  return null;
};

export default JSONExport;
