// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

import Icon from "src/icons";
import * as styles from "./styles";
import { dataStages } from "src/constants";
import { getCSVFieldMappings, getCSVFieldMappingsLoading } from "src/reducers";
import FieldMappingTable from "./FieldMappingTable";
import { uploadCSVToProcess, clearCsvUploadState } from "src/actions/file";
import SpinnerLoader from "src/components/Loader/SpinnerLoader";

import type { ProcessTemplateId } from "src/types";

type Props = {
  isOpen: boolean,
  onClose: () => void,
  templateId: ProcessTemplateId
};

const UploadCSVModal = ({ isOpen, onClose, templateId }: Props) => {
  const mappedFields = useSelector(({ app }) =>
    getCSVFieldMappings(app, templateId)
  );
  const loading = useSelector(({ app }) =>
    getCSVFieldMappingsLoading(app, templateId)
  );

  const dispatch = useDispatch();

  const handleFileUpload = event => {
    const fileData = event.target.files[0];
    dispatch(uploadCSVToProcess({ templateId, fileData }));
  };

  const showFieldMappingTable =
    dataStages.fetched === loading ||
    dataStages.updating === loading ||
    dataStages.updated === loading;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(
          clearCsvUploadState({
            templateId
          })
        );
        onClose();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent sx={styles.ModalContent}>
        <ModalHeader>Upload CSV</ModalHeader>
        <ModalCloseButton />
        <ModalBody sx={styles.ModalBody}>
          {dataStages.idle === loading && (
            <Button
              sx={styles.SelectCSVButton}
              as="label"
              htmlFor="fileInput"
              leftIcon={<Icon type="uploadCloud" />}
              colorScheme="blue"
              variant="outline"
            >
              Select CSV File
              <input
                id="fileInput"
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </Button>
          )}

          {dataStages.fetching === loading && <SpinnerLoader />}

          {showFieldMappingTable && (
            <FieldMappingTable
              templateId={templateId}
              mappedFields={mappedFields}
              loading={loading}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadCSVModal;
