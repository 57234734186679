// @flow

import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useCallback } from "react";
import { toast } from "react-toastify";

import ChartList from "src/components/Manage/Reports/Instances/Chart/ChartList";
import { addChartToDashboard } from "src/actions/dashboard";

import { getCurrentDashboard, getCurrentDashboardCharts } from "src/reducers";

import appLocation from "src/constants/location";
import type { AppState } from "src/types";

type Props = {
  handleClose: Function
};

const Charts = ({ handleClose }: Props) => {
  const dispatch = useDispatch();
  const outerRef = useRef(null);

  const dashboard = useSelector(({ app }) => getCurrentDashboard(app));
  const currentDashboardCharts = useSelector(({ app }) =>
    getCurrentDashboardCharts(app)
  );
  const seqNo = currentDashboardCharts.length || 1;

  const handleSelect = useCallback(
    (chart: string) => {
      const chartId = parseInt(chart);
      const isChartAlreadyPresent = currentDashboardCharts.find(
        chart => chart.id === chartId
      );
      if (!isChartAlreadyPresent) {
        dispatch(addChartToDashboard({ chart: chartId, dashboard, seqNo }));
        handleClose();
      } else {
        toast.error("This chart already exists in the current dashboard");
      }
    },
    [addChartToDashboard, handleClose, seqNo + 1, dashboard]
  );

  return (
    <ChartList
      location={appLocation.dashboard}
      handleClick={handleSelect}
      header="Available charts"
      handleClose={handleClose}
      parentRef={outerRef}
      newChart={false}
    />
  );
};

export default Charts;
