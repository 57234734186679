// @flow

import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
  useRef,
  useMemo
} from "react";
import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import moment from "moment";

import {
  DateBreak,
  ChatHistory as StyledChatHistory,
  LoadMessageMarker
} from "./styles";
import ScrollToLastMessage from "./ScrollToLastMessage";
import RequestAccess from "./RequestAccess";
import ByDay from "src/components/messages/ByDay";
import Prompt from "src/components/messages/Prompt";
import LoadingState from "src/components/LoadingState";
import usePrevious from "src/hooks/usePrevious";
import TypingIndicator from "src/components/chatroom/TypingIndicator";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";
import {
  getRecentChats,
  getCurrentUserId,
  getMessageCount,
  getChatroomPrivacy,
  getChatroomMembership,
  isProcessOwner as getIsProcessOwner,
  getWorkflowWhitelistMembership,
  getConversationModalVisibility,
  getConversationModalId,
  getIsSrwMobile,
  getCurrentRoomAccess,
  getCurrentRoomId
} from "src/reducers";
import { resyncMessages } from "src/actions/chatroom";
import type { ReactRef } from "src/types";
import { formatTimestamp } from "src/utils";

const History = () => {
  const dispatch = useDispatch();

  const currentRoomId = useSelector(({ app }) => getCurrentRoomId(app));
  const conversationModalRoomId = useSelector(({ app }) =>
    getConversationModalId(app)
  );
  const modal = useSelector(({ app }) => getConversationModalVisibility(app));

  // Use the roomID of the conversation modal if it's open
  // otherwise use the current room ID from the redux store
  const roomId = modal ? conversationModalRoomId : currentRoomId;

  const currentRoomAccessStatus = useSelector(({ app }) =>
    getCurrentRoomAccess(app)
  );
  const recentChats = useSelector(({ app }) => getRecentChats(app, roomId));

  const currentUser = useSelector(({ app }) => getCurrentUserId(app));
  const totalCount = useSelector(({ app }) => getMessageCount(app, roomId));
  const privacy = useSelector(({ app }) => getChatroomPrivacy(app, roomId));
  const membership = useSelector(({ app }) =>
    getChatroomMembership(app, roomId)
  );
  const isProcessOwner = useSelector(({ app }) =>
    getIsProcessOwner(app, roomId)
  );

  const whitelistMembership = useSelector(({ app }) =>
    getWorkflowWhitelistMembership(app)
  );

  const isMobile = useSelector(({ app }) => getIsSrwMobile(app));

  const messagesEnd: ReactRef = useRef();
  const messageMarker: ReactRef = useRef();

  const [lastMessage, setLastMessage] = useState(null);

  const prevRecentChats = usePrevious(recentChats);
  const prevRoomId = usePrevious(roomId);

  const scrollToBottom = useCallback(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: "instant" });
    }
  }, []);

  useEffect(() => {
    if (roomId) {
      scrollToBottom();
      setLastMessage(null);
    }
  }, [prevRoomId, roomId, modal]);

  // if recentChats just loaded for this chatroom
  // (lastMessage is null at this moment) then scroll to bottom
  // or if one message got added because of current user message or action
  // scroll to bottom (ensure one message didnt get added because of loadMore)
  useLayoutEffect(() => {
    if (
      lastMessage === null ||
      (prevRecentChats &&
        recentChats.length - prevRecentChats.length === 1 &&
        recentChats.length < totalCount &&
        recentChats[recentChats.length - 1].author === currentUser)
    ) {
      scrollToBottom();
    }
    // else when recentChats load during loadMore, scroll to last seen message
    else if (messageMarker.current && lastMessage !== recentChats[0].id)
      messageMarker.current.scrollIntoView({
        behavior: "instant",
        block: "start"
      });
    // update lastSeenMessage optimistically to top most message in recentChats
    if (recentChats.length > 0) setLastMessage(recentChats[0].id);
  }, [JSON.stringify(recentChats)]);

  const loadMore = useCallback(() => {
    if (recentChats.length < totalCount) {
      dispatch(resyncMessages(roomId, recentChats.length + 30, modal));
    }
  }, [JSON.stringify(recentChats), roomId, totalCount, modal]);

  const handleScroll = useCallback(
    (e: any) => {
      e.preventDefault();
      const reachedTop = e.target.scrollTop === 0;
      if (reachedTop) loadMore();
    },
    [loadMore]
  );

  // Split the messages into sub-sequences which belong to
  // the same day
  const recentChatsByDate = useMemo(
    () =>
      R.groupWith((fst, nxt) => {
        if (!fst.timestamp || !nxt.timestamp) return false;
        const firstTimestamp = formatTimestamp(fst.timestamp);
        const nextTimestamp = formatTimestamp(nxt.timestamp);
        return moment(firstTimestamp)
          .startOf("day")
          .isSame(moment(nextTimestamp).startOf("day"));
      }, recentChats),
    [JSON.stringify(recentChats)]
  );

  const renderMessageMarker = useCallback(
    () => <div ref={messageMarker} />,
    []
  );

  // First place the date block at the top of each sequence and then
  // render all the messages.
  const chats = useMemo(
    () =>
      recentChatsByDate.map((cs, idx) => {
        const fst = R.head(cs);
        let timestamp = {};
        if (fst && fst.timestamp) {
          ({ timestamp } = fst);
        }
        const date = moment(timestamp);
        return (
          <div style={{ position: "relative" }} key={idx}>
            {timestamp ? (
              <DateBreak>
                <span>
                  {moment().startOf("day").diff(date.startOf("day"), "days") ===
                  0
                    ? "Today"
                    : date.format("MMM DD, YYYY")}
                </span>
              </DateBreak>
            ) : null}
            <ByDay
              chats={cs}
              roomId={roomId}
              renderMessageMarker={renderMessageMarker}
              lastMessage={lastMessage}
            />
          </div>
        );
      }),
    [recentChatsByDate, roomId, lastMessage, renderMessageMarker]
  );

  if (
    privacy === "content" &&
    !membership &&
    !isProcessOwner &&
    !whitelistMembership
  )
    return <RequestAccess />;

  if (
    (recentChats.length === 0 && totalCount && totalCount !== 0) ||
    currentRoomAccessStatus === null ||
    currentRoomAccessStatus === currentRoomAccessStatuses.restricted
  ) {
    return <LoadingState type={isMobile ? "mobileHistory" : "history"} />;
  }

  return (
    <StyledChatHistory onScroll={handleScroll}>
      <ScrollToLastMessage scrollToBottom={scrollToBottom} />
      {[
        recentChats.length < totalCount && lastMessage === recentChats[0].id ? (
          <LoadMessageMarker key="loadMore">
            Loading more conversations . . .
          </LoadMessageMarker>
        ) : null,
        ...chats,
        <Prompt key="prompt" />,
        <div
          key="bottom"
          style={{ float: "left", clear: "both" }}
          ref={messagesEnd}
        />
      ]}
      <TypingIndicator roomId={roomId} />
    </StyledChatHistory>
  );
};

export default History;
