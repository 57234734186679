// @flow

import React from "react";
import { connect } from "react-redux";

import ProfileModal from "src/containers/filter/ProfileModal";
import NewOrgModal from "src/components/filter/Org/NewOrgModal";
import CloneOrgModal from "src/components/filter/Org/CloneOrgModal";
import ChangePassword from "src/components/filter/Profile/ChangePassword";
import AboutModal from "src/components/filter/Profile/AboutModal";
import { getCurrentUser, getCloneOrgModalStatus } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  currentUser: UnifizeUser,
  showProfileModal: boolean,
  showCreateOrgModal: boolean,
  closeMenu: boolean,
  showCloneOrgModal: boolean,
  showChangePasswordModal: boolean
};

const Modal = ({
  currentUser,
  showProfileModal,
  showCreateOrgModal,
  showCloneOrgModal,
  closeMenu,
  showChangePasswordModal
}: Props) => {
  return (
    <>
      {showProfileModal ? (
        <ProfileModal currentUser={currentUser} closeMenu={closeMenu} />
      ) : null}
      {showCreateOrgModal ? <NewOrgModal closeMenu={closeMenu} /> : null}
      {showCloneOrgModal ? <CloneOrgModal closeMenu={closeMenu} /> : null}
      {showChangePasswordModal ? (
        <ChangePassword closeMenu={closeMenu} />
      ) : null}
      <AboutModal />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUser(app),
  showProfileModal: app.modal.editDisplayName,
  showCreateOrgModal: app.modal.createOrg,
  showCloneOrgModal: getCloneOrgModalStatus(app),
  showChangePasswordModal: app.modal.changePassword
});

export default connect(mapStateToProps)(Modal);
